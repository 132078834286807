import React from "react";
import { useState, useEffect, useRef } from "react";
import UserService from "../../services/user.service";
import LinearProgress from '@mui/joy/LinearProgress';
import { Typography } from "@mui/material";
import Select, { useStateManager } from 'react-select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import CameraIcon from '@mui/icons-material/Camera';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from "axios";
import userService from "../../services/user.service";
import SignatureCanvas from 'react-signature-canvas';
import CachedIcon from '@mui/icons-material/Cached';
import AuthService from "../../services/auth.service";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const typeOptions = [
    {value:'Bank Statement' , label:'Bank Statement'},
    {value:'HM Revenue & Customs Letter' , label:'HM Revenue & Customs Letter'},
    {value:'Household Utility Bill' , label:'Household Utility Bill'},
    {value:'Local Authority Bill' , label:'Local Authority Bill'},
    {value:'Mortgage Statement' , label:'Mortgage Statement'},
    {value:'Property Rental Agreement' , label:'Property Rental Agreement'},
]

const documentOption = [
    {value: 'Birth Certificate', label: 'Birth Certificate'},
    {value: 'Identity Card', label: 'Identity Card'},
    {value: 'Passport', label: 'Passport'},
    {value: 'Visa', label: 'Visa'}
]


const DataCapture = () =>{
    const [ dataArc, setDataArc ] = useState()
    const [ value, setValue ] = useState(0)
    const [ steps, setSteps ] = useState(1)
    const [ name, setName ] = useState()
    const [ names, setNames ] = useState([])
    const [ firstName, setFirstName ] = useState()
    const [ lastName, setLastName ] = useState()
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ station, setStation ] = useState()
    const [ dob, setDob ] = useState()
    const [ stations, setStations ] = useState()
    const [ warningName, setWarningName ] = useState()
    const [ warningFirstName, setWarningFirstName ] = useState()
    const [ warningLastName, setWarningLastName ] = useState()
    const [ warningPhone, setWarningPhone ] = useState()
    const [ warningEmail, setWarningEmail ] = useState()
    const [ warningStation, setWarningStation ] = useState()
    const [ warningDob, setWarningDob ] = useState()
    const [ amzlExp, setAmzlExp ] = useState()
    const [ warningExp, setWarningExp ] = useState()
    const [ postCode, setPostCode ] = useState()
    const [ lookAddresses, setLookAddresses ] = useState([])
    const [ add, setAdd ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ county, setCounty ] = useState()
    const [ type, setType ] = useState()
    const [ date, setDate ] = useState()
    const [ warningPostCode, setWarningPostCode ] = useState()
    const [ warningAddress1, setWarningAddress1 ] = useState()
    const [ warningTown, setWarningTown ] = useState()
    const [ warningCounty, setWarningCounty ] = useState()
    const [ warningType, setWarningType ] = useState()
    const [ warningDate, setWarningDate ] = useState()
    const [ dlNo, setDlNo ] = useState()
    const [ dlExp, setDlExp ] = useState()
    const [ dlOrigin, setDlOrigin ] = useState()
    const [ dlPoints, setDlPoints ] = useState()
    const [ nino, setNino ] = useState()
    const [ warningNino, setWarningNino ] = useState()
    const [ ukLicense, setUkLicense ] = useState()
    const [ warningUk, setWarningUk ] = useState()
    const [ idDocument, setIdDocument ] = useState('Birth Certificate')
    const [ warningIdDocument, setWarningIdDocument ] = useState()
    const [ documentExpiry, setDocumentExpiry ] = useState()
    const [ warningDocumentExpiry, setWarningDocumentExpiry ] = useState()
    const [ terms1, setTerms1 ] = useState(false)
    const [ warningTerms1, setWarningTerms1 ] = useState()
    const [ terms2, setTerms2 ] = useState(false)
    const [ warningTerms2, setWarningTerms2 ] = useState()
    const sigCanvas1 = useRef({})
    const sigCanvas2 = useRef({})
    const [ today, setToday ] = useState()
    const userSt = AuthService.getCurrentUser();
    const [ user, setUser ] = useState()
    const [ files, setFiles ] = useState([])
    const [ poa, setPoa ] = useState()
    const [ fLicense, setFLicense ] = useState()
    const [ bLicense, setBLicense ] = useState()
    const [ cLicense, setCLicense ] = useState()
    const [ poNino, setPonino ] = useState()
    const [ firstId, setFirstId ] = useState()
    const [ secondId, setSecondId ] = useState()
    const [ thirdId, setThirdId ] = useState()
    const [ fourthId, setFourthId ] = useState()
    const [ badgePhoto, setBadgePhoto ] = useState()
    const [ warningToday, setWarningToday ] = useState()
    const [ warningUser, setWarningUser ] = useState()
    const [ trEnd, setTrEnd ] = useState()

    
    useEffect(()=>{
        UserService.getCaptureData().then(
            response =>{
                let data = response.data
                setDataArc(data)
                let placeholder = []
                console.log(response.data)
                data?.map(row =>{
                    placeholder.push({value: row.name, label: row.name})
                })
                setNames(placeholder)
            }
        )
        UserService.getStations().then(
            response =>{
                let data = response.data
                let placeholder = []
                console.log(data)
                data?.map(row =>{
                    placeholder.push({value: row.station_code, label: row.station_code})
                })
                setStations(placeholder)
            }
        )
        UserService.getAdd().then(
            response =>{
                setAdd(response.data)
            }
        )
    },[])
    useEffect(()=>{
        setUser(userSt.username)
    }, [userSt])

    const handleName = (e) =>{
        let name = e.value
        setName(name)
        if(name?.split(' ').length == 3){
            setFirstName(name?.split(' ')[0]+' '+name?.split(' ')[1])
            setLastName(name?.split(' ')[2])
        }else{
            setFirstName(name?.split(' ')[0])
            setLastName(name?.split(' ')[1])
        }
        dataArc?.map(row =>{
            if(name == row.name){
                setPhone(row.phone)
                setEmail(row.email)
                setDob(row.dob?.split('T').length == 2 ? row.dob?.split('T')[0] : row.dob)
                setDlNo(row.dl_no)
                setDlExp(new Date(row.dl_expiry).toLocaleDateString('en-GB'))
                setDlOrigin(row.dl_origin)
                setDlPoints(row.dl_points)
                setNino(row.nino)
                setTrEnd(row.onboarding_day2_date)
            }
        })
        if(e.value?.length > 0){
            setWarningName('')
        }else{
            setWarningName('Name selection required')
        }
    }
    useEffect(()=>{
        if(firstName){
            setWarningFirstName('')
        }
        if(lastName){
            setWarningLastName('')
        }
        if(phone){
            setWarningPhone('')
        }
        if(email){
            setWarningEmail('')
        }
        if(station){
            setWarningStation('')
        }
        if(dob){
            setWarningDob('')
        }
        if(amzlExp){
            setWarningExp('')
        }
    },[firstName, lastName, phone, email, station, dob, amzlExp])
    useEffect(()=>{
        if(postCode){
            setWarningPostCode("")
        }
        if(address1){
            setWarningAddress1("")
        }
        if(town){
            setWarningTown("")
        }
        if(county){
            setWarningCounty("")
        }
        if(type){
            setWarningType("")
        }
        if(date){
            setWarningDate("")
        }
       
    },[postCode, address1, town, county, type, date])
    useEffect(()=>{
        if(ukLicense){
            setWarningUk("")
        }
        if(nino){
            setWarningNino("")
        }
        if(idDocument){
            setWarningIdDocument("")
        }
        if(documentExpiry){
            setWarningDocumentExpiry("")
        }
    },[ukLicense, nino, idDocument, documentExpiry])
    useEffect(() => {
        if(terms1 == 'false'){
            setWarningTerms1("")
        }
        if(terms2 == 'false'){
            setWarningTerms2("")
        }
    },[terms1, terms2])
    useEffect(()=>{
        if(user){
            setWarningUser("")
        }
        if(today){
            setWarningToday("")
        }
        if(name){
            setWarningName("")
        }
    },[today, user, name])
    const handleFirstName = (e) =>{
        setFirstName(e.target.value)
    }
    const handleLastName = (e) =>{
        setLastName(e.target.value)
        
    }
    const handlePhone = (e) =>{
        setPhone(e.target.value)
    }
    const handleEmail = (e) =>{
        setEmail(e.target.value)
    }
    const handleStation = (e) =>{
        setStation(e.value)
    }
    const handleDob = (e) =>{
        setDob(e.target.value)
    }
    

    const handleSteps1 = () =>{
        if(name && firstName && lastName && phone && email && dob  && station){
            setSteps(steps+1)
        }else{
            if(!name){
                setWarningName('Name selection required')
            }else{
                setWarningName("")
            }
            if(!firstName){
                setWarningFirstName('First name required')
            }else{
                setWarningFirstName('')
            }
            if(!lastName){
                setWarningLastName('Last name required')
            }else{
                setWarningLastName('')
            }
            if(!phone){
                setWarningPhone('Phone number required')
            }else{
                setWarningPhone('')
            }
            if(!email){
                setWarningEmail('Email required')
            }else{
                setWarningEmail('')
            }
            if(!dob){
                setWarningDob('Dob required')
            }else{
                setWarningDob('')
            }
            if(!station){
                setWarningStation('Station required')
            }else{
                setWarningStation('')
            }
        }
    }
    const handlePrevious = () =>{
        setSteps(steps-1)
    }
    const handleSteps2 = () =>{
        if(!amzlExp){
            setWarningExp('Option required')
        }else{
            setSteps(steps+1)
        }
    }
    const handleSteps3 = () =>{
        if(postCode && address1 && town && county && type && date){
            setSteps(steps+1)
        }else{
            if(!postCode){
                setWarningPostCode("post code required")
            }else{
                setWarningPostCode("")
            }
            if(!address1){
                setWarningAddress1("address required")
            }else{
                setWarningAddress1("")
            }
            if(!town){
                setWarningTown("town required")
            }else{
                setWarningTown("")
            }
            if(!county){
                setWarningCounty("county required")
            }else{
                setWarningCounty("")
            }
            if(!date){
                setWarningDate("date required")
            }else{
                setWarningDate("")
            }
            if(!type){
                setWarningType("type required")
            }else{
                setWarningType("")
            }
        }
    }
    const handleSteps4 = () =>{
        if(ukLicense){
            setSteps(steps+1)
        }else{
            setWarningUk("selection reqire")
        }
    }
    const handleSteps5 = () =>{
        if(nino && idDocument && documentExpiry){
            setSteps(steps+1)
        }else{
            if(!nino){
                setWarningNino("nino required")
            }
            if(!idDocument){
                setWarningIdDocument("document required")
            }
            if(!documentExpiry){
                setWarningDocumentExpiry("expiry date required")
            }
        }
    }
    const handleSteps6 = () =>{
        if(terms1 == 'false' && terms2 == 'false'){
            setSteps(steps+1)
        }else{
            if(terms1 == 'true'){
                setWarningTerms1("check required")
            }
            if(terms2 == 'true'){
                setWarningTerms2("check required")
            }
        }
    }
    const handleSteps7 = () =>{
        setSteps(steps+1)
    }
    const handlePostCode = (e) =>{
            setPostCode(e.target.value?.toUpperCase())
    }
    const handleLookup = () =>{
        axios.get(`https://api.getAddress.io/autocomplete/${postCode}?api-key=${add}`).then(
            response => {
                let data = response.data.suggestions
                let placeholder = []
                data?.map(row =>{
                        placeholder.push({value: row.address, label: row.address})
                })
                setLookAddresses(placeholder)
            }
       )
    }
    const handleAddress = (e) =>{
        if(e.value?.split(',')?.length == 4){
            setAddress1(e.value?.split(',')[0])
            setAddress2('')
            setTown(e.value?.split(',')[1])
            setCounty(e.value?.split(',')[2])
        }else{
            setAddress1(e.value?.split(',')[0])
            setAddress2(e.value?.split(',')[1])
            setTown(e.value?.split(',')[2])
            setCounty(e.value?.split(',')[3])
        }
    }
    const clear1 = () => sigCanvas1.current.clear();
    const clear2 = () => sigCanvas2.current.clear();

    const handleSubmit = () => {
        if(user && name && today){
            const formData = new FormData();
            formData.append('name', name)
            formData.append('firstName', firstName)
            formData.append('lastName', lastName)
            formData.append('phone', phone)
            formData.append('email', email)
            formData.append('station', station)
            formData.append('dob', dob)
            formData.append('amzlExp', amzlExp)
            formData.append('postCode', postCode)
            formData.append('address1', address1)
            formData.append('address2', address2)
            formData.append('town', town)
            formData.append('county', county)
            formData.append('poaType', type)
            formData.append('poaDate', date)
            formData.append('dlNo', dlNo)
            formData.append('dlExp', dlExp)
            formData.append('dlOrigin', dlOrigin)
            formData.append('dlPoints', dlPoints)
            formData.append('ukLicense', ukLicense)
            formData.append('nino', nino)
            formData.append('idDocument', idDocument)
            formData.append('documentExpiry', documentExpiry)
            formData.append('terms1', terms1)
            formData.append('terms2', terms2)
            formData.append('today', today)
            formData.append('user', user)
            formData.append('trainingEnd', trEnd)
            formData.append('sigDriver', sigCanvas1.current.getTrimmedCanvas().toDataURL("image/png"))    
            formData.append('sigUser', sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png"))     
            formData.append('poaPicture', poa)
            formData.append('frontDl', fLicense)
            formData.append('backDl', bLicense)
            formData.append('checkDl', cLicense)
            formData.append('poNino', poNino)
            formData.append('firstId', firstId)
            formData.append('secondId', secondId)
            formData.append('thirdId', thirdId)
            formData.append('fourthId', fourthId)
            formData.append('badgePhoto', badgePhoto)
            console.log(formData)
            UserService.sendDataCapture(formData).then(
                result => {
                    console.log(result)
                }
            ).catch(err => console.log(err))
        }else{
            if(!name){
                setWarningName("name required")
            }
            if(!user){
                setWarningUser("user required")
            }
            if(!today){
                setWarningToday("date required")
            }
        }
    }

    return(
        <div className="dataArch">
            <div className="topPart">
            <h1 className="h1Profile">Data Capture</h1>
                <LinearProgress
                    determinate
                    variant="outlined"
                    color="primary"
                    size="sm"
                    thickness={24}
                    value={Number(steps)*13}
                    sx={{
                        '--LinearProgress-radius': '20px',
                        '--LinearProgress-thickness': '24px',
                        width: '100%',
                        marginTop: '20px',
                        color: 'rgb(45, 117, 131)'
                    }}
                    textColor="text.sec"
                >
                    <Typography
                        level="body-xl"
                        fontWeight="xxl"
                        textColor="common.white"
                        sx={{ mixBlendMode: 'plus-lighter' }}
                    >
                        Step: {`${steps} of 8`}
                    </Typography>
                </LinearProgress>
                {steps == 1 ? <p className="pClass pClassCorrection">This form is for the purpose of document capture for new starters with SKSO on the AMZL contract.</p> : ''}
            </div>
            {steps == 1 ? 
                <div className="bodyPArt">
                    <div className="flex">
                        <div className="flexComp">
                                <label for="name" className="label2 ">Candidate Name <span style={{color: '#b30000'}}>*</span></label>
                                <Select 
                                    options={names ? names : ''}
                                    styles={colourStyles}
                                    //value={{label: status, value: status}}
                                    onChange={e => handleName(e)}
                                /> 
                        </div>
                        
                    </div>
                    {warningName ? 
                        <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningName}</p> : ''
                    }
                    <div className="flex topC">
                        <div className="flexComp">
                            <label  className="label2 ">First Name <span style={{color: '#b30000'}}>*</span></label>
                            <input type="text" className="inputEdit" value={firstName ? firstName : ''} onChange={e => handleFirstName(e)}></input>
                        </div>
                        <div className="flexComp">
                            <label className="label2 ">Last Name <span style={{color: '#b30000'}}>*</span></label>
                            <input type="text" className="inputEdit " value={lastName ? lastName :''} onChange={e => handleLastName(e)}></input>
                        </div>
                    </div>
                    <div className="flex topC">
                        <div className="flexComp">
                            {warningFirstName ? 
                                <p className="pClass" style={{ color: '#ff3333', marginTop: '-10px'}}>{warningFirstName}</p> : ''
                            }
                        </div>
                        <div className="flexComp">
                            {warningLastName ? 
                                <p className="pClass" style={{ color: '#ff3333', marginTop: '-10px'}}>{warningLastName}</p> : ''
                            }
                        </div>
                    </div>
                    <div className="flex topC">
                        <div className="flexComp">
                                <label  className="label2 ">Phone <span style={{color: '#b30000'}}>*</span></label>
                                <input type="text"  className="lone loneCorrection" value={phone ? phone : ''} onChange={e => handlePhone(e)}></input>
                        </div>
                    </div>
                    {warningPhone ? 
                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', marginTop: '10px'}}>{warningPhone}</p> : ''
                    }
                    <div className="flex topC">
                        <div className="flexComp">
                                <label  className="label2 ">Email <span style={{color: '#b30000'}}>*</span></label>
                                <input type="text"  className="lone loneCorrection" value={email ? email : ''} onChange={e => handleEmail(e)}></input>
                        </div>
                    </div>
                    {warningEmail ? 
                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', marginTop: '10px'}}>{warningEmail}</p> : ''
                    }
                    <div className="flex topC">
                        <div className="flexComp">
                                <label for="name" className="label2 ">Delivery Station <span style={{color: '#b30000'}}>*</span></label>
                                <Select 
                                    options={stations ? stations : ''}
                                    styles={colourStyles}
                                    value={{label: station, value: station}}
                                    onChange={e => handleStation(e)}
                                /> 
                        </div>
                    </div>
                    {warningStation ? 
                        <p className="pClass pCorrection " style={{ color: '#ff3333'}}>{warningStation}</p> : ''
                    }
                    <div className="flex topC">
                        <div className="flexComp">
                                <label  className="label2 ">Date of Birth <span style={{color: '#b30000'}}>*</span></label>
                                <input type="text"  className="lone loneCorrection" value={dob ? dob : ''} onChange={e => handleDob(e)}></input>
                        </div>
                    </div>
                    {warningDob ? 
                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', marginTop: '10px'}}>{warningDob}</p> : ''
                    }
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSteps1}>
                        <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                        <div class="fill-container"></div>
                    </button>
                </div> 
            : steps == 2 ?
                <div className="bodyPArt">
                    <div className="pCorrection">
                        <label for="name" className="label2 ">Previous AMZL Experience <span style={{color: '#b30000'}}>*</span></label>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="radio-buttons-group"
                                onChange={e => setAmzlExp(e.target.value)}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                
                            </RadioGroup>
                        </FormControl>
                        {warningExp ? 
                            <p className="pClass pCorrection topC" style={{ color: '#ff3333', marginTop: '10px'}}>{warningExp}</p> : ''
                        }
                    </div>
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                        <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                        <div class="fill-container"></div>
                    </button>
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSteps2}>
                        <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                        <div class="fill-container"></div>
                    </button>
                </div> 
            : steps == 3 ?
             <div className="bodyPArt">
                <h3 className="h3Cap">Proof of Address</h3>
                <div className="flex">
                    <label  className="label2 ">Address</label>
                </div>
                <div className="flex topC">
                    <div className="flexComp">
                            {/*<label  className="label2 ">Email <span style={{color: '#b30000'}}>*</span></label>*/}
                            <p className="pClassC">Post Code</p>
                            <input type="text"  className="loneCap loneCorrection" value={postCode ? postCode : ''} onChange={e => handlePostCode(e)}></input>
                    </div>
                </div>
                {warningPostCode ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningPostCode}</p> : ''
                }
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleLookup}>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Lookup</span>
                    <div class="fill-container"></div>
                </button>
                {lookAddresses.length > 0 ? <div className="flex topC">
                    <div className="flexComp">
                            <p className="pClassC"></p>
                            <Select 
                                options={lookAddresses ? lookAddresses : ''}
                                styles={colourStyles}
                                //value={{label: station, value: station}}
                                onChange={e => handleAddress(e)}
                            /> 
                    </div> 
                </div>:''}
                <div className="flex topC">
                    <div className="flexComp">
                            <p className="pClassC">Name/Number and Street</p>
                            <input type="text"  className="lone loneCorrection" value={address1 ? address1 : ''} onChange={e => setAddress1(e.target.value)}></input>
                    </div>
                </div>
                {warningAddress1 ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningAddress1}</p> : ''
                }
                <div className="flex topC">
                    <div className="flexComp">
                            <p className="pClassC">Second Line</p>
                            <input type="text"  className="lone loneCorrection" value={address2 ? address2 : ''} onChange={e => setAddress2(e.target.value)}></input>
                    </div>
                </div>
                <div className="flex topC">
                    <div className="flexComp">
                        <p className="pClassC">Town/City</p>
                        <input type="text" className="inputEdit" value={town ? town : ''} onChange={e => setTown(e.target.value)}></input>
                    </div>
                    <div className="flexComp">
                        <p className="pClassC">County</p>
                        <input type="text" className="inputEdit " value={county ? county :''} onChange={e => setCounty(e.target.value)}></input>
                    </div>
                </div>
                <div className="flex topC">
                        <div className="flexComp">
                            {warningTown ? 
                                <p className="pClass" style={{ color: '#ff3333', marginTop: '-10px'}}>{warningTown}</p> : ''
                            }
                        </div>
                        <div className="flexComp">
                            {warningCounty ? 
                                <p className="pClass" style={{ color: '#ff3333', marginTop: '-10px'}}>{warningCounty}</p> : ''
                            }
                        </div>
                    </div>
                <div className="flex" style={{marginTop: '10px'}}>
                    <label  className="label2 ">Type <span style={{color: '#b30000', marginTop: '30px'}}>*</span></label>
                </div>
                <div className="flex topC" style={{marginTop: '5px'}}>
                    <div className="flexComp">
                        <p className="pClassC">Select Type</p>
                        <Select 
                            options={typeOptions}
                            styles={colourStyles}
                            //value={{label: station, value: station}}
                            onChange={e => setType(e.value)}
                        />                     </div>
                    <div className="flexComp">
                    </div>
                </div>
                {warningType ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningType}</p> : ''
                }
                <div className="flex" style={{marginTop: '10px'}}>
                    <label  className="label2 ">Date <span style={{color: '#b30000', marginTop: '30px'}}>*</span></label>
                </div>
                <div className="flex topC" style={{marginTop: '5px'}}>
                    <div className="flexComp">
                        <p className="pClassC">Select Date</p>
                        <input type="date" className="inputEdit " value={date ? date :''} onChange={e => setDate(e.target.value)}></input>
                        </div>
                    <div className="flexComp">
                    </div>
                </div>
                {warningDate ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningDate}</p> : ''
                }
                <div className="flex" style={{marginTop: '10px'}}>
                    <label  className="label2 ">Proof of Address </label>
                </div>
                <div className="flex topC" style={{marginTop: '5px'}}>
                    <div className="flexComp">
                        <p className="pClassC">Must be dated within 6 Months</p>
                    </div>
                    
                </div>
                <div className="uploadTake">
                    <div className="flex">
                        <div>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file"
                                type="file"
                                capture="environment"
                                onChange={(e) => setPoa(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file">
                                <CameraIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                            </label>
                        </div>
                        <div style={{marginRight: '20px'}}>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file"
                                type="file"
                                //capture="environment"
                                onChange={(e) => setPoa(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file">
                                <UploadFileIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                            </label>
                        </div>
                        <div>
                            <img src={poa ? URL.createObjectURL(poa) :''} />
                        </div>
                    </div>
                </div>

                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSteps3}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                    <div class="fill-container"></div>
                </button>
             </div>
            : steps == 4 ?
             <div className="bodyPArt">
                <h3 className="h3Cap">Driving License</h3>
                <div className="flex">
                    <label  className="label2 ">Driving License Details</label>
                </div>
                <div className="flex">
                    <p  className="pClassC">Please verify Driving License details </p>
                </div>
                <div className="flex">
                    <label  className="label2 ">Details</label>
                </div>
                <div className="flex topC">
                    <div className="flexComp">
                            <p className="pClassC">License number</p>
                            <input type="text"  className="lone loneCorrection" value={dlNo ? dlNo : ''} onChange={e => setDlNo(e.target.value)}></input>
                    </div>
                </div>
                <div className="flex topC">
                    <div className="flexComp">
                            <p className="pClassC">Licence Expiry</p>
                            <input type="text"  className="lone loneCorrection" value={dlExp ? dlExp : ''} onChange={e => setDlExp(e.target.value)}></input>
                    </div>
                </div>
                <div className="flex topC">
                    <div className="flexComp">
                        <p className="pClassC">Country</p>
                        <input type="text" className="inputEdit" value={dlOrigin ? dlOrigin : ''} onChange={e => setDlOrigin(e.target.value)}></input>
                    </div>
                    <div className="flexComp">
                        <p className="pClassC">Points</p>
                        <input type="text" className="inputEdit " value={dlPoints ? dlPoints :''} onChange={e => setDlPoints(e.target.value)}></input>
                    </div>
                </div>
                <div className="flex">
                    <label  className="label2 ">UK License <span style={{color: '#b30000', marginTop: '30px'}}>*</span></label>
                </div>
                <div className="pCorrection">
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            onChange={e => setUkLicense(e.target.value)}
                        >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    {warningUk ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningUk}</p> : ''
                    }
                </div>
                <div className="flex">
                    <label  className="label2 ">Front of Licence </label>
                </div>
                <div className="uploadTake">
                    <div className="flex">
                        <div>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file1"
                                type="file"
                                capture="environment"
                                onChange={(e) => setFLicense(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file1">
                                <CameraIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                            </label>
                        </div>
                        <div style={{marginRight: '15px'}}>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file2"
                                type="file"
                                //capture="environment"
                                onChange={(e) => setFLicense(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file2">
                                <UploadFileIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                            </label>
                        </div>
                        <div>
                            <img src={fLicense ? URL.createObjectURL(fLicense) : ''} />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <label  className="label2 ">Back of Licence </label>
                </div>
                <div className="uploadTake">
                    <div className="flex">
                        <div>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file3"
                                type="file"
                                capture="environment"
                                onChange={(e) => setBLicense(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file3">
                                <CameraIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                            </label>
                        </div>
                        <div style={{marginRight: '15px'}}>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file4"
                                type="file"
                                //capture="environment"
                                onChange={(e) => setBLicense(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file4">
                                <UploadFileIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                            </label>
                        </div>
                        <div>
                            <img src={bLicense ? URL.createObjectURL(bLicense) : ''} />
                        </div>
                    </div>
                </div>

                {ukLicense == 'Yes' ?
                <>
                    <p className="pClassC topC">Because {name.split(' ')[0]} has given details for a UK Driving Licence a check with DVLA is required. Please follow these steps.</p>
                    <ol>
                        <li>Ensure the following is correct;</li>
                        <br></br>
                        <ul>
                            <li>Driving Licence No is <strong>{dlNo}</strong></li>
                            <li>NI No is <strong>{nino}</strong></li>
                            <li>Post Code(for driving licence) is <strong>{postCode}</strong></li>
                        </ul>
                        <br></br>
                        <li><a href="https://www.gov.uk/view-driving-licence">Start Driving Licence Check</a></li>
                        <li>Click Start Now</li>
                        <li>Enter Details, Click <i>"I Agree"</i> and <i>"View Now"</i></li>
                        <li>Click <i>"Get your Check Code"</i></li>
                        <li>Click <i>"Get a Code"</i></li>
                        <li>Click <i>"Print or Save a Driving Summary"</i></li>
                        <li>Download/Open Summary</li>
                        <li>Take Screen Shot</li>
                        <li>Attach Screen Shot Below</li>
                    </ol>
                    <div className="flex">
                        <label  className="label2 ">Driving Licence Check - Image </label>
                    </div>
                    <div className="uploadTake">
                        <div className="flex">
                            <div style={{marginRight: '30px'}}>
                                <input
                                    accept="image/*"
                                    //className={classes.input}
                                    style={{display: 'none'}}
                                    id="icon-button-file5"
                                    type="file"
                                    //capture="environment"
                                    onChange={(e) => setCLicense(e.target.files[0])}
                                />
                                <label htmlFor="icon-button-file5">
                                    <UploadFileIcon className="incidentCamera" />
                                    <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                </label>
                            </div>
                            <div>
                                <img src={cLicense ? URL.createObjectURL(cLicense) : ''} />
                            </div>
                            
                        </div>
                    </div>
                </> : 
                <>
                    <p className="pClassC topC">As {name.split(' ')[0]} has supplied a Driving Licence which is not issued in the UK they must confirm they have not;</p>
                    <ul>
                        <li style={{color :'rgb(128, 128, 128)'}}>Having caused death through careless driving when unfit through drink</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Having caused death by careless driving when unfit through drugs</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Having caused death by careless driving with alcohol level above the limit</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Having caused death by careless driving then failing to supply a specimen for alcohol analysis</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Having caused death by driving: unlicensed, disqualified, or uninsured</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Dangerous driving</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Having caused manslaughter or culpable homicide while driving a vehicle</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Furious driving</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Driving or attempting to drive with alcohol above the legal limit</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Driving or attempting to drive while unfit through drink</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Driving or attempting to drive then failing to supply a specimen for analysis</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Driving or attempting to drive then refusing to give permission for analysis of a blood sample that</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>was taken without consent due to incapacity</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Failure to provide a specimen for analysis in circumstances other than driving or attempting to drive</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Failing to provide a specimen for a breath test</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Using a vehicle uninsured against third party risks</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Motor racing on the highway</li>
                        <li style={{color :'rgb(128, 128, 128)'}}>Aggravated taking of a vehicle</li>
                    </ul>
                </>}

                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSteps4}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                    <div class="fill-container"></div>
                </button>
             </div>
            : steps == 5 ?
            <div className="bodyPArt">
                <h3 className="h3Cap">Right to Work</h3>
                <div className="flex topC">
                    <div className="flexComp">
                            <label  className="label2 ">National Insurance No <span style={{color: '#b30000'}}>*</span></label>
                            <input type="text"  className="lone loneCorrection" value={nino ? nino : ''} onChange={e => setNino(e.target.value)}></input>
                    </div>
                </div>
                {warningNino ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningNino}</p> : ''
                }
                <label  className="label2 topC">Photo Of National Insurance No </label>
                <div className="uploadTake ">
                    <div className="flex">
                        <div>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file6"
                                type="file"
                                capture="environment"
                                onChange={(e) => setPonino(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file6">
                                <CameraIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                            </label>
                        </div>
                        <div style={{marginRight: '15px'}}>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file7"
                                type="file"
                                //capture="environment"
                                onChange={(e) => setPonino(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file7">
                                <UploadFileIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                            </label>
                        </div>
                        <div>
                            <img src={poNino ? URL.createObjectURL(poNino) : ''} />
                        </div>
                    </div>
                </div>
                <h3 className="h3Cap">ID</h3>
                <div className="flex topC">
                    <div className="flexComp">
                            <label  className="label2 ">ID Document <span style={{color: '#b30000'}}>*</span></label>
                            <Select 
                                options={documentOption ? documentOption : ''}
                                styles={colourStyles}
                                value={{label: idDocument, value: idDocument}}
                                onChange={e => setIdDocument(e.value)}
                            /> 
                    </div>
                </div>
                {warningIdDocument ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningIdDocument}</p> : ''
                }
                <div className="flex topC">
                    <div className="flexComp">
                            <label  className="label2 ">ID Expiry Date <span style={{color: '#b30000'}}>*</span></label>
                            <input type="date" className="inputEdit " value={documentExpiry ? documentExpiry :''} onChange={e => setDocumentExpiry(e.target.value)}></input>
                    </div>
                </div>
                {warningDocumentExpiry ? 
                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningDocumentExpiry}</p> : ''
                }
                {idDocument == 'Birth Certificate' ? 
                    <>
                        <label  className="label2 topC">1st Page </label>
                            <p className="pClassC">This will be</p>
                            <p className="pClassC">Photo Page of Birth Certicate</p>
                            <div className="uploadTake ">
                                <div className="flex">
                                    <div>
                                        <input
                                            accept="image/*"
                                            //className={classes.input}
                                            style={{display: 'none'}}
                                            id="icon-button-file8"
                                            type="file"
                                            capture="environment"
                                            onChange={(e) => setFirstId(e.target.files[0])}
                                        />
                                        <label htmlFor="icon-button-file8">
                                            <CameraIcon className="incidentCamera" />
                                            <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                        </label>
                                    </div>
                                    <div style={{marginRight: '15px'}}>
                                        <input
                                            accept="image/*"
                                            //className={classes.input}
                                            style={{display: 'none'}}
                                            id="icon-button-file9"
                                            type="file"
                                            //capture="environment"
                                            onChange={(e) => setFirstId(e.target.files[0])}
                                        />
                                        <label htmlFor="icon-button-file9">
                                            <UploadFileIcon className="incidentCamera" />
                                            <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                        </label>
                                    </div>
                                    <div>
                                        <img src={firstId ? URL.createObjectURL(firstId) : ''} />
                                    </div>
                                </div>
                            </div>
                    </>: idDocument == 'Identity Card' ? 
                    <>
                        <label  className="label2 topC">1st Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Photo Page of ID Card</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file8"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setFirstId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file8">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file9"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setFirstId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file9">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={firstId ? URL.createObjectURL(firstId) : ''} />
                                </div>
                            </div>
                        </div>
                        <label  className="label2 topC">2nd Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Back of the ID Card</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file10"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setSecondId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file10">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file11"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setSecondId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file11">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={secondId ? URL.createObjectURL(secondId) : ''} />
                                </div>
                            </div>
                        </div>
                    </>: idDocument == 'Passport' ? 
                    <>
                        <label  className="label2 topC">1st Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Photo Page of Passport</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file8"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setFirstId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file8">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file9"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setFirstId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file9">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={firstId ? URL.createObjectURL(firstId) : ''} />
                                </div>
                            </div>
                        </div>
                        <label  className="label2 topC">2nd Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Inside First Page of Passport</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file10"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setSecondId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file10">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file11"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setSecondId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file11">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={secondId ? URL.createObjectURL(secondId) : ''} />
                                </div>
                            </div>
                        </div>
                        <label  className="label2 topC">3rd Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Front Cover of Passport</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file20"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setThirdId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file20">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file21"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setThirdId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file21">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={thirdId ? URL.createObjectURL(thirdId) : ''} />
                                </div>
                            </div>
                        </div>
                        <label  className="label2 topC">4th Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Back Cover of Passport</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file22"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setFourthId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file22">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file23"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setFourthId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file23">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={fourthId ? URL.createObjectURL(fourthId) : ''} />
                                </div>
                            </div>
                        </div>
                    </>:
                    <>
                        <label  className="label2 topC">1st Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Photo Page of Visa</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file8"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setFirstId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file8">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file9"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setFirstId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file9">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={firstId ? URL.createObjectURL(firstId) : ''} />
                                </div>
                            </div>
                        </div>
                        <label  className="label2 topC">2nd Page </label>
                        <p className="pClassC">This will be</p>
                        <p className="pClassC">Back of Visa</p>
                        <div className="uploadTake ">
                            <div className="flex">
                                <div>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file10"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => setSecondId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file10">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                                    </label>
                                </div>
                                <div style={{marginRight: '15px'}}>
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file11"
                                        type="file"
                                        //capture="environment"
                                        onChange={(e) => setSecondId(e.target.files[0])}
                                    />
                                    <label htmlFor="icon-button-file11">
                                        <UploadFileIcon className="incidentCamera" />
                                        <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                                    </label>
                                </div>
                                <div>
                                    <img src={secondId ? URL.createObjectURL(secondId) : ''} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                {/**/}

                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSteps5}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                    <div class="fill-container"></div>
                </button>
            </div> 
            : steps == 6 ?
            <div className="bodyPArt">
                <label  className="label2 topC"><b>Declaration <span style={{color: '#b30000'}}>*</span></b></label>
                <p className="pClassC">This declaration includes;</p>
                <p className="pClassC" style={{marginLeft: '15px'}}>Non Disclosure Agreement<br></br>
                                        Fitness Declaration<br></br>
                                        Stop & Search Policy<br></br>
                                        Candidate SLA<br></br>
                                        Consent to Share Private Data<br></br>
                                        Non UK Licence Disclaimer (If Applicable)<br></br>
                                        Previous Engagement Disclaimer (If Applicable)</p>
                <div className="termsRec">
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Non Disclosure Agreement </b></label>
                    <p className="pClassC">During the course of your work/time at Skilled Solutions Ltd “(Skilled Solutions” and “us”), you will be invited to visit our premises, where you
may receive information relating to Skilled Solutions that is not known to the general public and is confidential and important to us. We call this our
“Confidential Information”.</p><br></br>
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Fitness Declaration </b></label>
                    <p className="pClassC">All couriers wishing to provide services must be medically fit enough to undertake the role
as a multi-drop courier. The core role involves a significant amount of driving and the safe
manual handling, manipulation, sorting and delivery of large numbers of consignments.</p><br></br>
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Stop & Search Policy </b></label>
                    <p className="pClassC">Amazon reserves the right to search you and/or any of your property on its premises (this includes Amazon’s car parks) and you agree that Amazon may
                    search you/and or your property.</p><br></br>
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Candidate SLA </b></label>
                    <p className="pClassC">These are the terms and conditions in which the candidate
                    is offered services with Skilled Solutions.</p><br></br>
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Consent to Share Private Data </b></label>
                    <p className="pClassC">Specifically in connection with section 26.6 of the GDPR Policy which states;</p><br></br>
                    <p className="pClassC"><i>"At random intervals clients of the company may request to view personal information held by the company
pertaining to its employees/contractors. The company will acknowledge only formal requests in writing with
reason and share only data that is requested. The company will not share data with its clients for marketing
purposes.".</i></p><br></br>
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Non UK Licence Disclaimer</b>  (If Applicable)</label>
                    <p className="pClassC">I hold a full and valid Non UK driving licence, that meets the standard of Skilled Solutions Ltd (the
                        company) and its client(s), and holds no points, penalties and/or endorsements.</p><br></br>
                    <label  className="label2 topC" style={{color: 'gray'}}><b>Previous Engagement Disclaimer</b>  (If Applicable)</label>
                    <p className="pClassC">Confirmation that the candidate approached Skilled Solutions of there own free will and Skilled Solutions will not intervene in any dispute between the candidate and the previous supplier.</p><br></br>
                </div>    
                <div className="flex">                
                    <input type="checkbox" id="vehicle2" name="vehicle2" value={terms1 == 'false' ? true : false} onChange={e => setTerms1(e.target.value)}></input>
                    <label for="vehicle2" style={{marginLeft: '10px', color: 'gray'}}><b>I agree to the Terms of Service</b> </label><br></br>
                </div> 
                {warningTerms1 ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningTerms1}</p> : ''
                }   
                <label  className="label2 topC"><b>Deductions Declaration<span style={{color: '#b30000'}}>*</span></b></label>
                <p className="pClassC">3rd Party Payments</p><br></br>
                <p className="pClassC" style={{color: 'gray'}}><b>The terms are to be read out to the associate.</b></p>
                <div className="termsRec">
                    <p className="pClassC">You are aware that Skilled Solutions works with 3rd party suppliers that offer services at discounted rates. If you want to use these services you give your consent to deduct any monies owed to the supplier from your Skilled Solutions self bill invoice.</p><br></br>
                    <p className="pClassC">The supplier will send invoice(s) by post or email to you when payments are due and request payments to be deducted from your balance owed with Skilled Solutions.</p><br></br>    
                    <p className="pClassC">If needed you will contact the supplier directly to discuss the invoice sent by them, however you accept that payments will still be made by Skilled Solutions from your balance and passed to the supplier, in the event of queries or over/under payments you will contact the supplier directly, if an invoice changes the supplier will contact Skilled Solutions.</p><br></br>    
                    <p className="pClassC">If the total owed exceeds your Skilled Solutions balance you accept that you must make arrangements with the supplier to settle the debt yourself.</p><br></br>
                    <p className="pClassC">You am aware that options for payment plans for certain invoices are at the suppliers discretion.</p><br></br>
                </div>
                <div className="flex">                
                    <input type="checkbox" id="vehicle3" name="vehicle3" value={terms2 == 'false' ? true : false} onChange={e => setTerms2(e.target.value)}></input>
                    <label for="vehicle3" style={{marginLeft: '10px', color: 'gray'}}><b>I agree to the Terms of Service</b> </label><br></br>
                </div>
                {warningTerms2 ? 
                    <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningTerms2}</p> : ''
                }   
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSteps6}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                    <div class="fill-container"></div>
                </button>
            </div>
            : steps == 7 ?
            <div className="bodyPArt">
                <label  className="label2 topC"><b>Badge Photo</b></label>
                <p className="pClassC">Please take a passport style photo of the candidate.</p><br></br>
                <div className="uploadTake ">
                    <div className="flex">
                        <div>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file12"
                                type="file"
                                capture="environment"
                                onChange={(e) => setBadgePhoto(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file12">
                                <CameraIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Take Picture</label>
                            </label>
                        </div>
                        <div style={{marginRight: '15px'}}>
                            <input
                                accept="image/*"
                                //className={classes.input}
                                style={{display: 'none'}}
                                id="icon-button-file13"
                                type="file"
                                //capture="environment"
                                onChange={(e) => setBadgePhoto(e.target.files[0])}
                            />
                            <label htmlFor="icon-button-file13">
                                <UploadFileIcon className="incidentCamera" />
                                <label className="label2 " style={{marginLeft: '35px'}}>Upload File</label>
                            </label>
                        </div>
                        <div>
                            <img src={badgePhoto ? URL.createObjectURL(badgePhoto) :''} />
                        </div>
                    </div>
                </div>

                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSteps7}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Next &gt;</span>
                    <div class="fill-container"></div>
                </button>
            </div>: 
            <div className="bodyPart">
                <div style={{borderBottom: '1px solid rgb(168, 168, 168)', paddingBottom: '50px'}}>
                    <label  className="label2 topC"><b>Candidate Signature <span style={{color: '#b30000'}}>*</span></b></label>
                    <div >
                        <SignatureCanvas 
                            penColor='black'
                            canvasProps={{className: 'sigCanvas'}}
                            ref={sigCanvas1}
                            //onChange={console.log(this.state.sigCanvas)}
                            className="correctionFieldsMobile"
                        />
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={clear1}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}><CachedIcon/></span>
                            <div class="fill-container"></div>
                        </button>
                    </div>
                    <div className="flex topC" style={{marginLeft: '-20px'}}>
                        <div className="flexComp">
                            <label  className="label2 topC"><b>Name <span style={{color: '#b30000'}}>*</span></b></label>
                            <input type="text"  className="lone loneCorrection" value={name ? name : ''} onChange={e => setName(e.target.value)}></input>
                        </div>
                    </div>
                    {warningName ? 
                        <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningName}</p> : ''
                    }
                    <div className="flex topC" style={{marginLeft: '-20px'}}>
                        <div className="flexComp">
                        <label  className="label2 topC"><b>Date <span style={{color: '#b30000'}}>*</span></b></label>
                                <input type="date" className="inputEdit " value={today} onChange={e => setToday(e.target.value)}></input>
                        </div>
                    </div>
                    {warningToday ? 
                        <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningToday}</p> : ''
                    }
                </div>
                <div style={{borderBottom: '1px solid rgb(168, 168, 168)', paddingBottom: '50px'}}>
                    <label  className="label2 topC"><b>Representative's Signature <span style={{color: '#b30000'}}>*</span></b></label>
                    <div >
                        <SignatureCanvas 
                            penColor='black'
                            canvasProps={{className: 'sigCanvas'}}
                            ref={sigCanvas2}
                            //onChange={console.log(this.state.sigCanvas)}
                            className="correctionFieldsMobile"
                        />
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={clear2}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}><CachedIcon/></span>
                            <div class="fill-container"></div>
                        </button>
                    </div>
                    <div className="flex topC" style={{marginLeft: '-20px'}}>
                        <div className="flexComp">
                            <label  className="label2 topC"><b>Name <span style={{color: '#b30000'}}>*</span></b></label>
                            <input type="text"  className="lone loneCorrection" value={user ? user : ''} onChange={e => setUser(e.target.value)}></input>
                        </div>
                    </div>
                    {warningUser ? 
                        <p className="pClass pCorrection" style={{ color: '#ff3333'}}>{warningUser}</p> : ''
                    }
                </div>

                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>&lt; Previous</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '10px'}} onClick={handleSubmit}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600'}}>Submit</span>
                    <div class="fill-container"></div>
                </button>
            </div>
            }
           
        </div>
    )
}
export default DataCapture;