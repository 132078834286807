import React from "react";
import { useState, useEffect } from "react";
import Select from 'react-select';
import UserService from "../../services/user.service";
import { useAsyncError } from "react-router-dom";
import AuthService from "../../services/auth.service";


const colourStylesR = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', minHeight: '10% !important', fontSize: 'large', marginTop: '1%', paddingLeft:'1%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}


const PmUpdate = () =>{
    const user = AuthService.getCurrentUser();
    const [ depots, setDepots ] = useState([])
    const [ week, setWeek ] = useState(new Date().getFullYear()+'-')
    const [ depot, setDepot ] = useState()
    const [ totalRoutes, setTotalRoutes ] = useState()
    const [ depotRoutes, setDepotRoutes ] = useState()
    const [ available, setAvailable ] = useState()
    const [ tom, setTom ] = useState()
    const [ warningDepot, setWarningDepot ] = useState()
    const [ warningTotal, setWarningTotal ] = useState()
    const [ warningWeek, setWarningWeek ] = useState()
    const [ warningDepotR , setWarningDepotR ] = useState()
    const [ warningAvailable, setWarningAvailable ] = useState()
    const [ warningTom, setWarningTom ] = useState()
    const [ types, setTypes ] = useState([])
    const [ type, setType ] = useState()
    const [ warningType, setWarningType ] = useState() 
    const [ notes, setNotes ] = useState()
    const [ statusCheck, setStatusCheck ] = useState()

    useEffect(()=>{
        UserService.getDepotList().then(
            response =>{
                let data = response.data
                let placeholder = []
                data?.map(dataRow =>{
                    placeholder.push({value: dataRow.depots, label: dataRow.depots})
                })
                setDepots(placeholder)
            }
        )
        UserService.getPmsdValidation().then(
            response =>{
                console.log(response.data)
                let data = response.data
                let placeholder = []
                data?.map(dataRow =>{
                    placeholder.push({value: dataRow.type, label: dataRow.type})
                })
                setTypes(placeholder)
            }
        )
    },[])

    const handleWeek = (e) =>{
        if(e.target.value?.length <= 7){
            setWeek(e.target.value)
        }
        if(e.target.value?.length == 7){
            setWarningWeek("")
        }
    }

    const handleDepot = (e) =>{
        setDepot(e.value)
        setWarningDepot("")
    }
    const handleType = (e) =>{
        setType(e.value)
        setWarningType("")
    }
    const handleTotal = (e) =>{
        setTotalRoutes(e.target.value)
        if(e.target.value?.toString()?.length > 0){
            setWarningTotal("")
        }else{
            setWarningTotal("please enter the total routes numebr")
        }
    }
    const handleDepotR = (e) =>{
        setDepotRoutes(e.target.value)
        if(e.target.value?.toString()?.length > 0){
            setWarningDepotR("")
        }else{
            setWarningDepotR("please enter the depot routes")
        }
    }
    const hadnleTom = (e) =>{
        setTom(e.target.value)
        if(e.target.value?.toString()?.length > 0 ){
            setWarningTom("")
        }else{
            setWarningTom("please enter the next day route numbers")
        }
    }
    const handleAvailable = (e) =>{
        setAvailable(e.target.value)
        if(e.target.value?.toString()?.length > 0){
            setWarningAvailable("")
        }else{
            setWarningAvailable("please enter the available da's number")
        }
    }

    const handleSubmit = () =>{
        setStatusCheck("")
        if(depot && week.length > 5 && type && totalRoutes && depotRoutes && available && tom ){
            let data = {
                location: depot,
                type: type, 
                week_no: week, 
                date: new Date().getDate()?.toString().padStart(2, '0')+'/'+(new Date().getMonth()+1)?.toString().padStart(2, "0")+'/'+new Date().getFullYear(),
                total_routes: totalRoutes, 
                depot_routes: depotRoutes, 
                volume_share: ((totalRoutes*100)/depotRoutes).toFixed(2), 
                tomorrows_routes: tom, 
                das_available: available,
                under_over: available-tom, 
                notes: notes,
                updatedBy: user.username
            }
            UserService.pmUpdate(data).then(
                response =>{
                    console.log(response)
                    if(response.status === 200){
                        setStatusCheck("Thank you for submitting!")
                    }
                }
            ).catch(err =>{
                setStatusCheck("Something went wrong. Please refresh and try again. If the issue persist please contact the office.")
            })
        }else{
            if(!depot){
                setWarningDepot("please select a depot")
            }else{
                setWarningDepot("")
            }
            if(week.length < 7){
                setWarningWeek("please insert the week number")
            }else{
                setWarningWeek("")
            }
            if(!totalRoutes){
                setWarningTotal("please enter the total routes numebr")
            }else{
                setWarningTotal("")
            }
            if(!depotRoutes){
                setWarningDepotR("please enter the depot routes")
            }else{
                setWarningDepotR("")
            }
            if(!available){
                setWarningAvailable("please enter the available da's number")
            }else{
                setWarningAvailable("")
            }
            if(!tom){
                setWarningTom("please enter the next day route numbers")
            }else{
                setWarningTom("")
            }
            if(!type){
                setWarningType("please select a type of service")
            }else{
                setWarningType("")
            }
        }
    }

    return(
        <div className="incidentArch">
            <h1 className="h1Profile">Daily Update</h1>
            {!statusCheck ? <>
                <div className="flex topC">
                    <div className="flexComp">
                        <label  className="label2">Depot <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                        <Select 
                            options={depots}
                            styles={colourStylesR}
                            //value={{label: status, value: status}}
                            onChange={e => handleDepot(e)}
                        /> 
                        {warningDepot ? <p className="warning">{warningDepot}</p> : ''}
                    </div>
                    <div className="flexComp">
                        <label  className="label2">Type <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                        <Select 
                            options={types}
                            styles={colourStylesR}
                            //value={{label: status, value: status}}
                            onChange={e => handleType(e)}
                        /> 
                        {warningType ? <p className="warning">{warningType}</p> : ''}
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Week No <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                            <input type="text" className="lonePm" value={week} onChange={e => handleWeek(e)} ></input>
                            {warningWeek ? <p className="warning">{warningWeek}</p> : ''}
                    </div>
                </div>
                <div className="flex ">
                    <div className="flexComp">
                        <label  className="label2">Total Routes <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                        <input type="number" className="inputRPm" /*value={postCode ? postCode : ''}*/ onChange={e => handleTotal(e)}></input>
                        {warningTotal ? <p className="warning">{warningTotal}</p> : ''}
                    </div>
                    <div className="flexComp">
                        <label  className="label2">Depot Routes <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                        <input type="number" className="inputRPm" /*value={postCode ? postCode : ''}*/  onChange={e => handleDepotR(e)}></input>
                        {warningDepotR ? <p className="warning">{warningDepotR}</p> : ''}
                    </div>
                </div>
                <div className="flex ">
                    <div className="flexComp">
                            <label for="share" className="label2 ">Volume Share % </label>
                            <input type="text" name="share" className="lonePm" value={totalRoutes && depotRoutes ? ((totalRoutes*100)/depotRoutes).toFixed(2) : ''} /*onChange={e => setTown(e.target.value)}*/ disabled></input>
                    </div>
                </div>
                <div className="flex ">
                    <div className="flexComp">
                            <label for="tom" className="label2 ">Tomorrow's Routes <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                            <input type="number" name="tom" className="lonePm " /*value={town ? town : ''}*/ onChange={e => hadnleTom(e)}></input>
                            {warningTom ? <p className="warning">{warningTom}</p> : ''}
                    </div>
                </div>
                <div className="flex ">
                    <div className="flexComp">
                        <label  className="label2">DA's Available <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                        <input type="number" className="inputRPm" /*value={postCode ? postCode : ''}*/ onChange={e => handleAvailable(e)}></input>
                        {warningAvailable ? <p className="warning">{warningAvailable}</p> : ''}
                    </div>
                    <div className="flexComp">
                        <label  className="label2">Spares/Shortage of DA's </label>
                        <input type="number" className="inputRPm" value={tom && available ? available-tom : ''} /*onChange={e => setSpare(e.target.value)}*/ disabled></input>
                    </div>
                </div>
                <div className="flex ">
                    <div className="flexComp">
                            <label for="tom" className="label2 ">Notes </label>
                            <textarea rows={5} name="tom" className="lonePm " /*value={town ? town : ''}*/ onChange={e => setNotes(e.target.value)}></textarea>
                    </div>
                </div>
            
                
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                    <div class="fill-container"></div>
                </button>
            </>: <h3 className="thank">{statusCheck}</h3>
            }
        </div>
    )
}
export default PmUpdate;