import React from "react";
import { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, gridClasses, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
  }));

const OnboardingView = () =>{
    const [ onboardingData, setOnboardingData ] = useState()
    const [ displayData, setDisplayData ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ stations, setStations ] = useState([])
    const [ selected, setSelected ] = useState()
    const [ field, setField ] = useState()
    const [ transition, setTransition ] = useState('10px !important')
    const [ arrow, setArrow ] = useState(<ArrowBackIosNewRoundedIcon sx={{ color: 'rgb(45, 117, 131)'}}/>)

    useEffect(()=>{
      UserService.getStations().then(
        response =>{
          let data = response.data
          let ele = []
          console.log(data)
          data?.map(row =>{
            ele.push(row.station_code)
          })
          setStations(ele)
        }
      ).catch(err => console.log(err.message))
    },[])

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'account_no',
            headerName: 'Account No',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Pending", "Onboarding", "Active", "Inactive", "Not Suitable", "Not Interested", "Offboarded"]
        },
        {
          field: 'name',
          headerName: 'Name',
          headerClassName: 'table-header',
          width: 300,
          editable: true,
        },
          {
            field: 'station',
            headerName: 'Station',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: stations ? stations : ''
          },
          {
            field: 'phone',
            headerName: 'Phone',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'email_skso',
            headerName: 'Skso Email',
            headerClassName: 'table-header',
            width: 300,
            editable: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            headerClassName: 'table-header',
            width: 350,
            editable: true,
          },
          {
            field: 'notes',
            headerName: 'Notes',
            headerClassName: 'table-header',
            width: 500,
            editable: true,
          },
          {
            field: 'post_code',
            headerName: 'Post Code',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'nationality',
            headerName: 'Nationality',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dob',
            headerName: 'Dob',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'nino',
            headerName: 'Nino',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'vehicle_source',
            headerName: 'Vehicle Source',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Rent from Company", "Owner Driver"]
          },
          {
            field: 'active_utr',
            headerName: 'Utr',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_no',
            headerName: 'Dl no',
            headerClassName: 'table-header',
            width: 300,
            editable: true,
          },
          {
            field: 'dl_expiry',
            headerName: 'Dl Expiry',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'dl_origin',
            headerName: 'Dl Origin',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_points',
            headerName: 'Dl Points',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_endorsements',
            headerName: 'Dl Endorsements',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_sharecode',
            headerName: 'Dl Sharecode',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'onboarding_rtw_check',
            headerName: 'RTW Check',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Yes", "No", "N/A", ""]
          },
          {
            field: 'apply_da_date',
            headerName: 'DA Date',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'apply_da_time',
            headerName: 'Da Time',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'apply_da_result',
            headerName: 'Da Result',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Pass", "Fail",""]
          },
          {
            field: 'onboarding_bgc_sent',
            headerName: 'BGC Sent',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'onboarding_bgc_submitted',
            headerName: 'BGC Submitted',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'onboarding_bgc_completed',
            headerName: 'BGC Completed',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'apply_onboarding_skso_acc',
            headerName: 'Onboarding Skso Acc',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'onboarding_mentor',
            headerName: 'Mentor',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'onboarding_schedule',
            headerName: 'Schedule',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Yes", "No", ""]
          },
          {
            field: 'onboarding_disprz_id',
            headerName: 'Disprz Id',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'onboarding_day1_date',
            headerName: 'Training Day 1',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'onboarding_day2_date',
            headerName: 'Training Day 2',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'onboarding_bgc_fee',
            headerName: 'BGC Fee',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'onboarding_date',
            headerName: 'Onboarding Date',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'amzl_id',
            headerName: 'Amzl Id',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'amzl_profile_link',
            headerName: 'Amzl Profile',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
            renderCell: (params) => {
              const handleClick = () =>{
                window.open(params.row.amzl_profile_link)
              }
               return (
                  <button class="buttonSkOn" onClick={handleClick} style={{backgroundColor: 'rgb(45, 117, 131)', color:'white', height: '40px', marginTop: '5px'}}>
                    <span class="button-text" style={{top: '-35%'}}>Amzl profile</span>
                    <div class="fill-container"></div>
                  </button>
               )
            }
          },
        ]

    useEffect(()=>{
        UserService.getOnboardingData().then(
            response =>{
                setOnboardingData(response.data)
            }
        ).catch(err => console.log(err))
    },[])

    useEffect(()=>{
        let data = []
        onboardingData?.map(row =>{
            data.push({
                id: row.entry,
                account_no: row.account_no,
                name: row.name,
                station: row.station,
                amzl_id: row.amzl_id,
                amzl_profile_link: row.amzl_profile_link,
                phone: row.phone,
                email_skso: row.email_skso,
                email: row.email,
                post_code: row.post_code,
                nationality: row.nationality,
                dob: row.dob,
                age: row.age,
                nino: row.nino,
                vehicle_source: row.vehicle_source,
                active_utr: row.active_utr,
                dl_no: row.dl_no,
                dl_expiry: row.dl_expiry,
                dl_origin: row.dl_origin,
                dl_points: row.dl_points,
                dl_endorsements: row.dl_endorsements,
                dl_sharecode: row.dl_sharecode,
                apply_da_date: row. apply_da_date,
                apply_da_time: row.apply_da_time,
                apply_da_result: row.apply_da_result,
                apply_onboarding_skso_acc: row.apply_onboarding_skso_acc,
                onboarding_bgc_completed: row.onboarding_bgc_completed,
                onboarding_bgc_fee: row.onboarding_bgc_fee,
                onboarding_rtw_check: row.onboarding_rtw_check,
                onboarding_bgc_sent: row.onboarding_bgc_sent,
                onboarding_bgc_submitted: row.onboarding_bgc_submitted,
                onboarding_date: row.onboarding_date,
                onboarding_day1_date: row.onboarding_day1_date,
                onboarding_day2_date: row.onboarding_day2_date,
                onboarding_disprz_id: row.onboarding_disprz_id,
                onboarding_mentor: row.onboarding_mentor,
                onboarding_schedule: row.onboarding_schedule,
                status: row.status,
                notes: row. notes
            })
        })
        setDisplayData(data)
    },[onboardingData])

    const handldeInput = (e) =>{
        let data = []
        onboardingData?.map(row =>{
            if(row.name?.toLowerCase().match(e.target.value?.toLowerCase())){
                data.push({
                    id: row.entry,
                    account_no: row.account_no,
                    name: row.name,
                    station: row.station,
                    amzl_id: row.amzl_id,
                    amzl_profile_link: row.amzl_profile_link,
                    phone: row.phone,
                    email_skso: row.email_skso,
                    email: row.email,
                    post_code: row.post_code,
                    nationality: row.nationality,
                    dob: row.dob,
                    age: row.age,
                    nino: row.nino,
                    vehicle_source: row.vehicle_source,
                    active_utr: row.active_utr,
                    dl_no: row.dl_no,
                    dl_expiry: row.dl_expiry,
                    dl_origin: row.dl_origin,
                    dl_points: row.dl_points,
                    dl_endorsements: row.dl_endorsements,
                    dl_sharecode: row.dl_sharecode,
                    apply_da_date: row. apply_da_date,
                    apply_da_time: row.apply_da_time,
                    apply_da_result: row.apply_da_result,
                    apply_onboarding_skso_acc: row.apply_onboarding_skso_acc,
                    onboarding_bgc_completed: row.onboarding_bgc_completed,
                    onboarding_bgc_fee: row.onboarding_bgc_fee,
                    onboarding_rtw_check: row.onboarding_rtw_check,
                    onboarding_bgc_sent: row.onboarding_bgc_sent,
                    onboarding_bgc_submitted: row.onboarding_bgc_submitted,
                    onboarding_date: row.onboarding_date,
                    onboarding_day1_date: row.onboarding_day1_date,
                    onboarding_day2_date: row.onboarding_day2_date,
                    onboarding_disprz_id: row.onboarding_disprz_id,
                    onboarding_mentor: row.onboarding_mentor,
                    onboarding_schedule: row.onboarding_schedule,
                    status: row.status,
                    notes: row. notes
                })
            }
        })
        setDisplayData(data)
    }

    const handleCellEdit = (params) =>{
        console.log(params)
        if(params.status == "Not Suitable" || params.status == "Not Interested" || params.status == "Offboarded"){
            UserService.updateBasicInfo({entry: params.id, status: params.status}).then(
              response =>{
                window.location.reload(false)
              }
           ).catch(err => console.log(err))
        }else{
          const data = {
              entry: params.entry,
              account_no: params.account_no,
              name: params.name,
              station: params.station,
              amzl_id: params.amzl_id,
              amzl_profile_link: params.amzl_profile_link,
              phone: params.phone,
              email_skso: params.email_skso,
              email: params.email,
              post_code: params.post_code,
              nationality: params.nationality,
              dob: params.dob,
              age: params.age,
              nino: params.nino,
              vehicle_source: params.vehicle_source,
              active_utr: params.active_utr,
              dl_no: params.dl_no,
              dl_expiry: params.dl_expiry,
              dl_origin: params.dl_origin,
              dl_points: params.dl_points,
              dl_endorsements: params.dl_endorsements,
              dl_sharecode: params.dl_sharecode,
              apply_da_date: params. apply_da_date,
              apply_da_time: params.apply_da_time,
              apply_da_result: params.apply_da_result,
              apply_onboarding_skso_acc: params.apply_onboarding_skso_acc,
              onboarding_bgc_completed: params.onboarding_bgc_completed,
              onboarding_bgc_fee: params.onboarding_bgc_fee,
              onboarding_rtw_check: params.onboarding_rtw_check,
              onboarding_bgc_sent: params.onboarding_bgc_sent,
              onboarding_bgc_submitted: params.onboarding_bgc_submitted,
              onboarding_date: params.onboarding_date,
              onboarding_day1_date: params.onboarding_day1_date,
              onboarding_day2_date: params.onboarding_day2_date,
              onboarding_disprz_id: params.onboarding_disprz_id,
              onboarding_mentor: params.onboarding_mentor,
              onboarding_schedule: params.onboarding_schedule,
              status: params.status,
              notes: params. notes
          }
          UserService.updateOnboardingData(data).then(
              response =>{
                  console.log(response)
              }
          ).catch(err => console.log(err))
        }
        return params
    }

    const paperTransition = async () =>{
      if(transition == '10px !important'){
        setTransition('-290px !important')
        setArrow(<ArrowForwardIosRoundedIcon sx={{ color: 'rgb(45, 117, 131)'}}/>)
      }else{
        setTransition('10px !important')
        setArrow(<ArrowBackIosNewRoundedIcon sx={{ color: 'rgb(45, 117, 131)'}}/>)
      }
      
    }

    return(
        <>
            <div className="flex">
                <input type="text" className="inputIncidents" onChange={e => handldeInput(e)} placeholder="search..."></input>
            </div>
            <Box sx={{ height: 3100, width: 8360, paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGrid
                
                // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={displayData}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    //pinnedColumns: { left: ['name'], right: ['actions'] } 
                    },
                }}
                sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                  backgroundColor: '#D35858',
                  color: '#1a3e72',
                },
                [`.${gridClasses.cell}.hot`]: {
                  backgroundColor: '#A6D358',
                  color: '#1a3e72',
                },}}
                pageSizeOptions={[100]}
                onCellClick={param => {
                  setField(param.field)
                  setSelected(param?.row?.name)
                }}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                processRowUpdate={handleCellEdit}
                onRowClick={params => console.log(params)}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                    id: false
                }}
                getCellClassName={(params) => {
                  if (params.field === 'city' || params.value == null) {
                    return '';
                  }
                  return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                }}
                />
            </Box> 
            <Box
              sx={{
                display: 'flex',
                position: 'fixed',
                flexWrap: 'wrap',
                top: 1060,
                left: -10,
                '& > :not(style)': {
                  m: 1,
                  width: 328,
                  height: 228,
                  paddingLeft: 2,
                  paddingTop: 2
                },
              }}
            >
              {/*'-290px !important' */}
            <Paper elevation={12} sx={{marginLeft: transition }}>
              <div className="flex">
                <div style={{width: '90%'}}>
                  <h3 style={{marginLeft: '10px', marginTop: '10px', fontSize: '20px'}}><b>Selected Applicant: </b></h3>
                  <h3 style={{marginRight: '30px',textAlign: 'right', marginTop: '10px', fontSize: '20px', color: 'rgb(136, 136, 136)', borderBottom: '1px solid rgb(136, 136, 136)'}}>{selected}</h3>
                  <h3 style={{marginLeft: '10px', marginTop: '10px', fontSize: '20px'}}><b>Selected Field: </b></h3>
                  <h3 style={{marginRight: '30px',textAlign:'right', marginTop: '10px', fontSize: '20px', color: 'rgb(136, 136, 136)', borderBottom: '1px solid rgb(136, 136, 136)'}}>
                    {
                      field == 'account_no' ? 'Account No' :
                      field == 'status' ? 'Status' :
                      field == 'name' ? 'Name' :
                      field == 'station' ? 'Station' :
                      field == 'phone' ? 'Phone' :
                      field == 'email_skso' ? 'Skso Email' :
                      field == 'email' ? 'Email' :
                      field == 'notes' ? 'Notes' :
                      field == 'post_code' ? 'Post Code' :
                      field == 'nationality' ? 'Nationality' :
                      field == 'dob' ? 'Dob' :
                      field == 'age' ? 'Age' :
                      field == 'status' ? 'Status' :
                      field == 'nino' ? 'Nino' :
                      field == 'vehicle_source' ? 'Vehicle Source' :
                      field == 'active_utr' ? 'Utr' :
                      field == 'dl_no' ? 'Dl no' :
                      field == 'dl_expiry' ? 'Dl Expiry' :
                      field == 'dl_origin' ? 'Dl Origin' :
                      field == 'dl_points' ? 'Dl Points' :
                      field == 'dl_endorsements' ? 'Dl Endorsements' :
                      field == 'dl_sharecode' ? 'Dl Sharecode' :
                      field == 'onboarding_rtw_check' ? 'RTW Check' :
                      field == 'apply_da_date' ? 'DA Date' :
                      field == 'apply_da_time' ? 'DA Time' :
                      field == 'apply_da_result' ? 'DA Result' :
                      field == 'apply_da_date' ? 'DA Date' :
                      field == 'onboarding_bgc_sent' ? 'BGC Sent' :
                      field == 'onboarding_bgc_submitted' ? 'BGC Submitted' :
                      field == 'onboarding_bgc_completed' ? 'BGC Completed' :
                      field == 'apply_onboarding_skso_acc' ? 'Onboarding Skso Acc' :
                      field == 'onboarding_mentor' ? 'Mentor' :
                      field == 'onboarding_schedule' ? 'Schedule' :
                      field == 'onboarding_disprz_id' ? 'Disprz Id' :
                      field == 'onboarding_day1_date' ? 'Training Day 1' :
                      field == 'onboarding_day2_date' ? 'Training Day 2' :
                      field == 'onboarding_bgc_fee' ? 'BGC Fee' :
                      field == 'onboarding_date' ? 'Onboarding Date' :
                      field == 'amzl_id' ? 'Amzl Id' : ''
                    }
                  </h3>
                </div>
                <div style={{textAlign: 'right', maxWidth: '20px !important'}}>
                  <button className="ocButton" onClick={paperTransition}>{arrow}</button>
                </div>
              </div>
            </Paper>
          </Box>           
        </>
    )
}
export default OnboardingView;