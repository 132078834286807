import React from "react";
import { useState, useEffect } from "react";
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import UserService from "../../services/user.service";
import EditIcon from '@mui/icons-material/Edit';
import { fontSize, width } from "@mui/system";
import KeyIcon from '@mui/icons-material/Key';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { OutlinedInput, InputLabel, MenuItem, FormControl, Stack, Chip, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    }
  }));

const depOptions = [
    {label: 'Senior Management', value: 'Senior Management'},
    {label: 'IT Support', value: 'IT Support'},
    {label: 'Recruitment', value: 'Recruitment'},
    {label: 'Tambor Fleet', value: 'Tambor Fleet'},
    {label: 'Tracfast', value: 'Tracfast'},
    {label: 'Regional Manager', value: 'Regional Manager'},
    {label: 'DME4', value: 'DME4'},
    {label: 'DRH1', value: 'DRH1'},
    {label: 'DXE1', value: 'DXE1'},
    {label: 'DRM4', value: 'DRM4'},
    {label: 'DSS2', value: 'DSS2'},
    {label: 'Driver', value: 'Driver'}
]
const depOptionsN = [
    'Senior Management',
    'IT Support',
    'Recruitment',
    'Tambor Fleet',
    'Tracfast',
    'Regional Manager',
    'DME4-SKSO',
    'DRH1-SKSO',
    'DXE1-SKSO',
    'DRM4-SKSO',
    'DSS2-SKSO',
    'Driver'
]
const upOptions = [
    {label:'User', value: 1},
    {label:'Moderator', value:2},
    {label:'Admin', value:3}
]

const UsersAccess = () =>{
    const [ users, setUsers ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ username, setUsername ] = useState()
    const [ email, setEmail ] = useState()
    const [ department, setDepartment ] = useState()
    const [ id, setId ] = useState()
    const [ openEdit, setOpenEdit ] = useState(false)
    const [ message, setMessage ] = useState()
    const [ openAlert, setOpenAlert ] = useState(false)
    const [ messageAlert, setMessageAlert ] = useState()
    const [ openAccess, setOpenAccess ] = useState(false)
    const [ role, setRole ] = useState()
    const [ selectedRole, setSelectedRole ] = useState()
    const [ anchorElE, setAnchorElE ] = useState(null)
    const [ anchorElA, setAnchorElA ] = useState(null)
    const [ anchorElD, setAnchorElD ] = useState(null)
    const [ search, setSearch ] = useState([])
    const [ departamentS, setDepartmentS ] = useState([])
    const classes = useStyles();
    const [formState, setFormState] = useState({
      userRoles: []
    });


    useEffect(()=>{
        UserService.getUsers().then(
            response => {
                setUsers(response.data)
            }
        ).catch(err => console.log(err))
    }, [])
    useEffect(()=>{
        if(!users){
            setLoading(true)
        }else{
            setLoading(false)
        }
    },[users])

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'role',
            headerName: 'Role',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
        {
          field: 'username',
          headerName: 'Name',
          headerClassName: 'table-header',
          width: 288,
          editable: false,
        },
        {
          field: 'email',
          headerName: 'Email',
          headerClassName: 'table-header',
          width: 288,
          editable: false,
        },
       {
          field: 'department',
          headerName: 'Department',
          headerClassName: 'table-header',
          width: 368,
          editable: false,
        },
        {
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 288,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleParams(param.row)} onMouseEnter={handlePopoverOpenE} onMouseLeave={handlePopoverCloseE}/>
                        <KeyIcon style={{paddingLeft: '5%', fontSize:'40px'}} onClick={e => handleAccess(param)} onMouseEnter={handlePopoverOpenA} onMouseLeave={handlePopoverCloseA}/>
                        <DeleteIcon style={{paddingLeft: '5%', fontSize:'40px'}} onClick={e => handleAlert(param)} onMouseEnter={handlePopoverOpenD} onMouseLeave={handlePopoverCloseD}/>
                    </>
            )
          },
        ]

    const handleParams = (params) =>{
        console.log(params)
        setId(params.id)
        setUsername(params.username)
        setEmail(params.email)
        setDepartment(params.department)
        setOpenEdit(true)
    }
    const handleAccess = (param) =>{
        setId(param.row.id)
        setUsername(param.row.username)
        setRole(param.row.role)
        setOpenAccess(true)
    }
    const handleAlert = (param) =>{
        setId(param.row.id)
        setOpenAlert(true)
        setUsername(param.row.username)
    }
    const handleClose = () =>{
        setOpenEdit(false)
        setMessage('')
        window.location.reload(false)
    }
    const handleYes = () =>{
        UserService.deleteUser({id: id}).then(
            response => {
                setMessageAlert(response.data)
                setTimeout(()=>{
                    setOpenAlert(false)
                },2000)
                setMessageAlert('')
                window.location.reload(false)
            }
        )
    }
    const handleNo = () =>{
        setOpenAlert(false)
    }
    const handleCloseAccess = () =>{
        setOpenAccess(false)
    }
    const handleUpdateAccess = () =>{
        const data = {userId : id, roleId: selectedRole}
        UserService.updateRole(data).then(
            response => {
                console.log(response)
                setOpenAccess(false)
            }
        )
        window.location.reload(false)
    }
    const handlePopoverOpenE = (event) => {
        setAnchorElE(event.currentTarget);
    };
    
      const handlePopoverCloseE = () => {
        setAnchorElE(null);
    };
    const handlePopoverOpenA = (event) => {
        setAnchorElA(event.currentTarget);
    };
    
      const handlePopoverCloseA = () => {
        setAnchorElA(null);
    };
    const handlePopoverOpenD = (event) => {
        setAnchorElD(event.currentTarget);
    };
    
      const handlePopoverCloseD = () => {
        setAnchorElD(null);
    };
    const handleSearch = (e) =>{
        const searchE = e.target.value
        setSearch([])
            users?.map(user =>{
                let departments = ''
                if(user.department && user.department?.split(',').length > 1){
                    let jsonPl = JSON.parse(user.department)
                    jsonPl.map((element, index) =>{
                        if(index == jsonPl.length-1){
                            departments += ' | '+element + ' | '
                        }else{
                            departments += ' | '+element
                        }
                    }) 
                }else{
                    departments = user.department
                }
                let data = {id: user.id, role: user.roleId == 1 ? 'User': user.roleId == 2 ? 'Moderator' : 'Admin', username: user.username ,email: user.email, department: user.department && user.department?.split(',').length > 1 ? departments : user.department ? JSON.parse(user.department)[0] : ''}
                if(user.username?.toLowerCase().match(searchE) || user.email?.toLowerCase().match(searchE) || user.department?.toLowerCase().match(searchE)){
                    setSearch(oldSearch =>[...oldSearch, data])
                }
            })
    }
    const handleFieldChange = event => {
        //event.persist();
        setFormState(formState => ({
          ...formState,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value
        }));
      };
    useEffect(()=>{
        console.log(formState.userRoles)
    },[formState])

    const openPopE = Boolean(anchorElE);
    const openPopA = Boolean(anchorElA);
    const openPopD = Boolean(anchorElD);
    let display = [];
    if(users){
    users?.map(usersRow => {
        let departments = ''
        if(usersRow.department && usersRow.department?.split(',').length > 1){
            let jsonPl = JSON.parse(usersRow.department)
            jsonPl.map((element, index) =>{
                if(index == jsonPl.length-1){
                    departments += ' | '+element + ' | '
                }else{
                    departments += ' | '+element
                }
            }) 
        }else{
            departments = usersRow.department
        }
            display.push(
                {id: usersRow.id, role: usersRow.roleId == 1 ? 'User': usersRow.roleId == 2 ? 'Moderator' : 'Admin', username: usersRow.username ,email: usersRow.email, department: usersRow.department && usersRow.department?.split(',').length > 1 ? departments : usersRow.department ? JSON.parse(usersRow.department)[0] : ''}
                ) 
            })  
    }

    const handleSubmit = () =>{
        const data = {id: id, username: username, email: email, department: JSON.stringify(formState.userRoles)}
        UserService.updateUsers(data).then(
            response =>{
                setMessage(response.data)
            })
    }
    
  
    
    return(
        <>
            <div className="searchBar">
                <input type="text" name='search' className="fullInput" placeholder="search user"/*defaultValue={email}*/ onChange={e => handleSearch(e)}  />
            </div>
            {search.length == 0 ?
                <Box sx={{ height: 1150, width: 1600, paddingLeft: '0%' }}>
                <DataGrid
                
            // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={display}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    },
                }}
                sx={{fontSize: '20px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                //onCellClick={handleClick}
            
                />
            </Box>:
            <Box sx={{ height: 1150, width: 1600, paddingLeft: '0%' }}>
                <DataGrid
                
            // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={search}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    },
                }}
                sx={{fontSize: '20px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                //onCellClick={handleClick}
            
                />
            </Box>
            }
            <React.Fragment >
                <Dialog
                    //fullScreen
                    open={openEdit}
                    TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{width: '100%'}}
                >
                    <DialogTitle >{!message ? "Edit User":''}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    {!message ?   
                        <>
                            <div className="inlineLnx">
                                <label for='departament' className="eCorrection">Department</label>
                                <TextField
                                    classes={{ root: classes.root }}
                                    select
                                    name="userRoles"
                                    id="userRoles"
                                    variant="outlined"
                                    label="userRoles"
                                    SelectProps={{
                                        multiple: true,
                                        value: formState.userRoles,
                                        onChange: handleFieldChange
                                    }}
                                    >
                                        {depOptionsN.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                        ))}
                                 </TextField>
                                {/*<Select
                                    options={depOptions}
                                    //className="form-control"
                                    //name="department"
                                    //value={this.state.department}
                                    onChange={e => setDepartment(e.value)}
                                    //validations={[required, email]}
                                    />*/}
                            </div> 
                            <div className="inlineLnx">
                                <label for='name' className="eCorrection">Name</label>
                                <input type="text" name='name' className="fullInput" defaultValue={username} onChange={e => setUsername(e.target.value)}  />
                            </div>
                            <div className="inlineLnx">
                                <label for='email' className="eCorrection">Email</label>
                                <input type="text" name='email' className="fullInput" defaultValue={email} onChange={e => setEmail(e.target.value)}  />
                            </div>
                        </>:
                        message == 'User updated.' ? 
                        <div className="usuccess">
                            {message}
                        </div>:
                        <div className="ufail">
                            {message}
                        </div>
                    }
                         
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {!message ? <Button onClick={handleSubmit}>Submit</Button> :''}
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment >
                <Dialog
                    //fullScreen
                    open={openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{width: '100%'}}
                >
                    <DialogTitle >
                        {!messageAlert?
                            <div className="alertDelete">
                                Are you sure you want to delete the user {username}?
                            </div>: 
                            <div className="alertDelete">
                                {messageAlert}
                            </div>
                        }
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleYes}>Yes</Button>
                        <Button onClick={handleNo}>No</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment >
                <Dialog
                    //fullScreen
                    open={openAccess}
                    TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{width: '100%'}}
                >
                    <DialogTitle >
                        <div className="inlineLnx">
                            <label for='name' className="eCorrection" style={{fontWeight: 600}}>Name:</label>
                            <h5 name='name'>{username}</h5>
                        </div>  
                        <div className="inlineLnx">
                            <label for='role' className="eCorrection" style={{fontWeight: 600}}>Current Role:</label>
                            <h5 name='role'>{role}</h5>
                        </div> 
                        <div className="inlineLnx">
                            <label for='updateTo' className="eCorrection" style={{fontWeight: 600}}>Updating to:</label>
                            <Select
                                    options={upOptions}
                                    //className="form-control"
                                    //name="department"
                                    //value={this.state.department}
                                    onChange={e => setSelectedRole(e.value)}
                                    //validations={[required, email]}
                                />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpdateAccess}>Update</Button>
                        <Button onClick={handleCloseAccess}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={openPopE}
                anchorEl={anchorElE}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverCloseE}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>Edit User</Typography>
            </Popover>
            <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={openPopA}
                anchorEl={anchorElA}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverCloseA}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>User Access</Typography>
            </Popover>
            <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={openPopD}
                anchorEl={anchorElD}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverCloseD}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>Delete User</Typography>
            </Popover>
        </>
    )
}
export default UsersAccess;