import React from "react";
import { useState, useEffect, useRef } from "react";
import UserService from "../../services/user.service";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import Badge from '@mui/material/Badge';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import active from './img/active.png';
import inactive from './img/inactive.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import Slide from '@mui/material/Slide';
import AuthService from "../../services/auth.service";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import BadgeMail from "./badge.component";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DepartTickets = (props) =>{
    const user = AuthService.getCurrentUser();
    const departament = props.department
    const [ onTickets, setOnTickets ] = useState([])
    const [ offTickets, setOffTickets ] = useState([])
    const [ offTickets2, setOffTickets2 ] = useState([])
    const [ openFragment, setOpenFragment ] = useState(false)
    const [ category, setCategory ] = useState()
    const [ ticketId, setTicketId ] = useState()
    const [ status, setStatus ] = useState()
    const [ timestamp, setTimestamp ] = useState()
    const [ to, setTo ] = useState()
    const [ closedBy, setClosedBy ] = useState()
    const [ closedDate, setClosedDate ] = useState()
    const [ note, setNote ] = useState()
    const [ message, setMessage ] = useState()
    const [ messages, setMessages ]  = useState([])
    const [ interval, setInt ] = useState()
    const [ schedule, setSchedule ] = useState(false)
    const [dataD, setDataD] = useState([])
    const [ closingNote, setClosingNote ] = useState()
    const [ closing_note, setClosing_note] = useState()
    const [ dataS, setDataS ] =useState([])
    const [ regD, setRegD ] = useState()


    const messagesEndRef = useRef()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "instant", block: "end" })
    }

    const handleOpenFragment = (e, ticket_id, to_department, notes, timestamp, category, reg, status, closed_by, closed_date, closing_note) =>{
        setCategory(category)
        setTicketId(ticket_id)
        setStatus(status)
        setTimestamp(timestamp)
        setTo(to_department)
        setClosedBy(closed_by)
        setClosedDate(closed_date)
        setClosing_note(closing_note)
        setNote(notes)
        setRegD(reg)
        setOpenFragment(true)
        setInt(setInterval(() =>{
            setDataD([])
            
            UserService.getMessages({ticket_id: ticket_id}).then(
                response => {
                    console.log(response)
                    let data = response.data
                
                    data.map((mess, index) =>{
                        
                        if(user.username == mess.username){
                            //setMessages(oldMessages =>[ ...oldMessages, <h4>{'['+mess.createdAt?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[0]+']['+mess.createdAt?.split('T')[1].split('.')[0]+ '][You]: '+ mess.message}</h4>])
                            setDataD(oldDataD =>[...oldDataD, <h4>{'['+mess.createdAt?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[0]+']['+mess.createdAt?.split('T')[1].split('.')[0]+ '][You]: '+ mess.message}</h4>])
                        }else{
                            //setMessages(oldMessages =>[ ...oldMessages, <h4>{'['+mess.createdAt?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[0]+']['+mess.createdAt?.split('T')[1].split('.')[0]+ ']['+mess.username+']: '+ mess.message}</h4>])
                            setDataD(oldDataD =>[...oldDataD, <h4>{'['+mess.createdAt?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[0]+']['+mess.createdAt?.split('T')[1].split('.')[0]+ ']['+mess.username+']: '+ mess.message}</h4>])
                        }
                    })
                    if(data.length == 0){
                        setDataD(['No messages'])
                    }
                    //setMessages(dataD)
                    UserService.markMessages({ticket_id: ticket_id, user: user.username}).then(
                        response =>{
                            console.log(response)
                        }
                    )
                }
            ).catch(err => console.log(err))
        }, 1000))
    }

    useEffect(()=>{
        if(dataD.length > 0){
            setMessages(dataD)
        }
    }, [dataD])

    useEffect(() => {
        scrollToBottom()
        setSchedule(false)
      }, [messages]);

    const handleCloseFragment = () =>{
        console.log(interval)
        clearInterval(interval)
        setOpenFragment(false)
        setMessages([])
    }

    useEffect(()=>{
        setOnTickets([])
        setOffTickets([])
        UserService.getDepartmentTickets({dep: departament}).then(
            response=>{
                let data = response.data
                setDataS(response.data)
                data.map(ticket =>{
                    console.log(typeof(ticket.department))
                    if(ticket.status == 'Open'){
                        setOnTickets(oldOnTickets =>[...oldOnTickets, 
                            <Card sx={{ minWidth:260, maxWidth: 260,  minHeight: 250, maxHeight: 250 , marginLeft: 2, marginBottom: 2}}>
                            <CardContent>
                                <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                    <ReceiptLongIcon /> Id: {'#'+ticket.ticket_id} <RadioButtonCheckedIcon style={{color: 'green'}} />
                                    
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    From: {ticket.username}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    Role: {ticket.department?.match("Senior Management") || 
                                            ticket.department?.match("IT Support") || 
                                            ticket.department?.match("Recruitment") || 
                                            ticket.department?.match("Fleet") || 
                                            ticket.department?.match("Tracfast") || 
                                            ticket.department?.match("Regional Management") ? 'Head Office': 
                                            ticket.department?.match("DME4-SKSO") || 
                                            ticket.department?.match("DRH1-SKSO") || 
                                            ticket.department?.match("DXE1-SKSO") || 
                                            ticket.department?.match("DRM4-SKSO") || 
                                            ticket.department?.match("DSS2-SKSO") ? 'OSM': 'Driver'}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    {ticket.category ? "Regarding: "+ticket.category : ''}
                                </Typography>
                              
                            </CardContent>
                            <CardActions>
                            
                                <Button size="small" onClick={e => handleOpenFragment(e, ticket.ticket_id, ticket.to_department, ticket.notes, ticket.timestamp, ticket.category, ticket.reg, ticket.status, ticket.closed_by, ticket.closed_date, ticket.closing_note)}>More details</Button>
                                <BadgeMail dep={user.username} ticketId={ticket.ticket_id} />
                            </CardActions>
                        </Card>
                        ])
                    }else{
                        setOffTickets(oldOnTickets =>[...oldOnTickets, 
                            <Card sx={{ minWidth:260, maxWidth: 260,  minHeight: 250, maxHeight: 250 , marginLeft: 2, marginBottom: 2}}>
                            <CardContent>
                                <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                <ReceiptLongIcon /> Id: {'#'+ticket.ticket_id} <MotionPhotosOffIcon style={{color: 'red'}} />
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    From: {ticket.username}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    Regarding: {ticket.category}
                                </Typography>
                            
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={e => handleOpenFragment(e, ticket.ticket_id, ticket.to_department, ticket.notes, ticket.timestamp, ticket.category, ticket.reg, ticket.status, ticket.closed_by, ticket.closed_date, ticket.closing_note)}>More details</Button>
                                <BadgeMail dep={user.username} ticketId={ticket.ticket_id} />
                            </CardActions>
                        </Card>
                        ])
                    }
                })

            }
        ).catch(err => console.log(err))
    },[departament])

    

    const handleSendMessage = () =>{
        let data = {ticket_id: ticketId, username: user.username, message: message, seen_user: 'Unseen', seen_dep: 'Seen'}
        UserService.sendMessage(data).then(
            response => {
                console.log(response)
                setMessage('')
                setSchedule(true)
            }
        ).catch(err => console.log(err))
    }

    const handleCloseTicket = () =>{
        const data = {ticket_id: ticketId, closing_note: closingNote, closed_by: user.username, closed_date: new Date(), status: 'Closed'}
        UserService.closeTicket(data).then(
            response =>{
                console.log(response)
                window.location.reload(false)
            }).catch(err => console.log(err))
    }
    const handleSearchOngoing = (e) =>{
        setOnTickets([])
        dataS.map(ticket =>{
            console.log(ticket)
            console.log(typeof(ticket.department))
            if((ticket.username?.toLowerCase().match(e.target.value?.toLowerCase()) || ticket.ticket_id?.match(e.target.value)) && ticket.status == 'Open'){
                setOnTickets(oldOnTickets =>[...oldOnTickets, 
                    <Card sx={{ minWidth:260, maxWidth: 260,  minHeight: 250, maxHeight: 250 , marginLeft: 2, marginBottom: 2}}>
                    <CardContent>
                        <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                            <ReceiptLongIcon /> Id: {'#'+ticket.ticket_id} <RadioButtonCheckedIcon style={{color: 'green'}} />
                            
                        </Typography>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            From: {ticket.username}
                        </Typography>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            Role: {ticket.department?.match("Senior Management") || 
                                    ticket.department?.match("IT Support") || 
                                    ticket.department?.match("Recruitment") || 
                                    ticket.department?.match("Fleet") || 
                                    ticket.department?.match("Tracfast") || 
                                    ticket.department?.match("Regional Management") ? 'Head Office': 
                                    ticket.department?.match("DME4-SKSO") || 
                                    ticket.department?.match("DRH1-SKSO") || 
                                    ticket.department?.match("DXE1-SKSO") || 
                                    ticket.department?.match("DRM4-SKSO") || 
                                    ticket.department?.match("DSS2-SKSO") ? 'OSM': 'Driver'}
                        </Typography>
                        
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            {ticket.category ? "Regarding: "+ticket.category : ''}
                        </Typography>
                      
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={e => handleOpenFragment(e, ticket.ticket_id, ticket.to_department, ticket.notes, ticket.timestamp, ticket.category, ticket.reg, ticket.status, ticket.closed_by, ticket.closed_date, ticket.closing_note)}>More details</Button>
                        <BadgeMail dep={user.username} ticketId={ticket.ticket_id} />
                    </CardActions>
                </Card>
                ])
            }
        })    
    }
    const handleSearchClosed = (e) =>{
        setOffTickets([])
        dataS.map(ticket =>{
            if((ticket.ticket_id?.match(e.target.value) || ticket.username?.toLowerCase().match(e.target.value.toLowerCase())) && ticket.status == 'Closed'){
                setOffTickets(oldOnTickets =>[...oldOnTickets, 
                    <Card sx={{ minWidth:260, maxWidth: 260,  minHeight: 250, maxHeight: 250 , marginLeft: 2, marginBottom: 2}}>
                    <CardContent>
                        <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                            <ReceiptLongIcon /> Id: {'#'+ticket.ticket_id} <MotionPhotosOffIcon style={{color: 'red'}} />
                            
                        </Typography>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            From: {ticket.username}
                        </Typography>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            Role: {ticket.department?.match("Senior Management") || 
                                    ticket.department?.match("IT Support") || 
                                    ticket.department?.match("Recruitment") || 
                                    ticket.department?.match("Fleet") || 
                                    ticket.department?.match("Tracfast") || 
                                    ticket.department?.match("Regional Management") ? 'Head Office': 
                                    ticket.department?.match("DME4-SKSO") || 
                                    ticket.department?.match("DRH1-SKSO") || 
                                    ticket.department?.match("DXE1-SKSO") || 
                                    ticket.department?.match("DRM4-SKSO") || 
                                    ticket.department?.match("DSS2-SKSO") ? 'OSM': 'Driver'}
                        </Typography>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            {ticket.category ? "Regarding: "+ticket.category : ''}
                        </Typography>
                      
                    </CardContent>
                    <CardActions>
                    
                        <Button size="small" onClick={e => handleOpenFragment(e, ticket.ticket_id, ticket.to_department, ticket.notes, ticket.timestamp, ticket.category, ticket.reg, ticket.status, ticket.closed_by, ticket.closed_date, ticket.closing_note)}>More details</Button>
                        <BadgeMail dep={user.username} ticketId={ticket.ticket_id} />
                    </CardActions>
                </Card>
                ])
            }
        })
    }

    return(
        <div className="dashArch">
            <header>
                <h1 className="title">{departament}</h1>
            </header>
            <div className="depTickets">
                <h3 className="titleT" style={{marginLeft: '10px'}}>
                    Ongoing Tickets  <input className="inputTickets" onChange={e => handleSearchOngoing(e)} placeholder="search..."></input>
                </h3>
                <div className="flexTickets">
                    {onTickets}
                </div>
            </div>
            <div className="depTickets">
                <h3 className="titleT" style={{marginLeft: '10px'}}>
                    Closed Tickets <input className="inputTickets" onChange={e => handleSearchClosed(e)} placeholder="search..."></input>
                </h3>
                <div className="flexTickets">
                    {offTickets}
                </div>
            </div>
            <React.Fragment >
                <Dialog
                    fullScreen
                    open={openFragment}
                    TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{width: '100%'}}
                >
                    <DialogTitle style={{background: '#E8E8E8'}}>
                       <h2 className="title">Ticket No: #{ticketId}</h2>
                       
                    </DialogTitle >
                    <DialogContent style={{background: '#E8E8E8'}}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="ticketCont">
                            <div className="ticketDet">
                                <h3 className="titleT">Details</h3>
                                <h4>Status: {status}</h4>
                                <h4>To department: {to}</h4>
                                <h4>Date created: {timestamp?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+timestamp?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+timestamp?.split('T')[0].split('-')[0]}</h4>
                                <h4>Reason: {category}</h4>
                                <h4>{regD ? 'Reg: '+regD:''}</h4>
                                <h4>Notes: {note}</h4>
                                <h4>{status == 'Closed'? 'Closed by: '+ closedBy : ''}</h4>
                                <h4>{status == 'Closed'? 'Closed Date: '+ closedDate?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+closedDate?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+closedDate?.split('T')[0].split('-')[0] : ''}</h4><br></br>
                                <h4>Closing note:</h4>
                                {status == 'Open' ? <textarea rows={5} className="closingNote" onChange={e => setClosingNote(e.target.value)}></textarea> : <h4>{closing_note}</h4>}<br></br>
                                {status == 'Open' ? !closingNote ?<button className="button" style={{backgroundColor: 'gray'}}>Close Ticket</button> : <button className="button" onClick={handleCloseTicket}>Close Ticket</button>:''}
                            </div>
                            <div className="ticketChat">
                                    <div className="chatWind">
                                        {messages.length ==  0 ? <div className="loadingMess"><Box sx={{ display: 'flex', textAlign: 'center' }}>
                                                                    <CircularProgress />
                                                                </Box>Retriving messages...</div> : messages[0] == 'No messages' ? 'No Messages to display' : messages 
                                                                }
                                    <div ref={messagesEndRef} />
                                </div>
                                <div className="flex">
                                    {status == 'Open' ?<input type="text" className="chatInp" onChange={e => setMessage(e.target.value)} value={message}></input> : <input type="text" className="chatInp" onChange={e => setMessage(e.target.value)} value={message} disabled></input>}
                                    {status == 'Open' ? !schedule ? <SendIcon sx={{fontSize: '50px', marginTop: '5%', marginLeft: '2%', color: '#27C5D9'}} onClick={handleSendMessage}/> : <ScheduleSendIcon sx={{fontSize: '50px', marginTop: '5%', marginLeft: '2%', color: '#27C5D9'}}/> : <SendIcon sx={{fontSize: '50px', marginTop: '5%', marginLeft: '2%', color: 'gray'}}/>}
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{background: '#E8E8E8'}}>
                        <Button onClick={handleCloseFragment} >Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default DepartTickets;