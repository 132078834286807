import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Countries from "./countries";
import people from "./officePeople";
import CameraIcon from '@mui/icons-material/Camera';
import UploadFileIcon from '@mui/icons-material/UploadFile';


const steps = [
    'step 1',
    'step 2',
    'step 3',
    'step 4',
    'step 5',
    'step 6',
];

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const colourStylesR = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', height: '8%', fontSize: 'large', marginLeft:'2% !important', marginTop: '1%', paddingLeft:'1%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}


const IncidentForm = () =>{
    const [ hour, setHour ] = useState()
    const [ hourWarning, setHourWarning ] = useState(false)
    const [ minutes, setMinutes ] = useState()
    const [ minutesWarning, setMinutesWarning ] = useState(false)
    const [ names, setNames ] = useState([])
    const [ step, setStep ] = useState(0)
    const [ name1, setName1 ] = useState()
    const [ name2, setName2 ] = useState()
    const [ date, setDate ] = useState()
    const [ dateWarning, setDateWarning ] = useState(false)
    const [ nameWarning, setNameWarning ] = useState(false)
    const [ timeWarning, setTimeWarning ] = useState(false)
    const [ cat, setCat ] = useState('third')
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ county, setCounty ] = useState()
    const [ postCode, setPostCode ] = useState()
    const [ email, setEmail ] = useState()
    const [ phone, setPhone ] = useState()
    const [ first, setFirst ] = useState()
    const [ last, setLast ] = useState()
    const [ country, setCountry ] = useState()
    const [ name2Warning, setName2Warning] = useState(false)
    const [ address1Warning, setAddress1Warning ] = useState(false)
    const [ townWarning, setTownWarning ] = useState(false)
    const [ countyWarning, setCountyWarning ] = useState(false)
    const [ postCodeWarning, setPostCodeWarning ] = useState(false)
    const [ emailWarning, setEmailWarning ] = useState(false)
    const [ phoneWarning, setPhoneWarning ] = useState(false)
    const [ firstWarning, setFirstWarning ] = useState(false)
    const [ lastWarning, setLastWarning ] = useState(false)
    const [ countryWarning, setCountryWarning ] = useState(false)
    const [ incidentAddress1, setIncidentAddress1 ] = useState()
    const [ incidentAddress2, setIncidentAddress2 ] = useState()
    const [ incidentTown, setIncidentTown ] = useState()
    const [ incidentCounty, setIncidentCounty ] = useState()
    const [ incidentPostCode, setIncidentPostCode ] = useState()
    const [ incidentCountry, setIncidentCountry ] = useState()
    const [ incidentDetails, setIncidentDetails ] = useState()
    const [ incidentAddress1Warning, setIncidentAddress1Warning ] = useState(false)
    const [ incidentTownWarning, setIncidentTownWarning ] = useState(false)
    const [ incidentCountyWarning, setIncidentCountyWarning ] = useState(false)
    const [ incidentPostCodeWarning, setIncidentPostCodeWarning ] = useState(false)
    const [ incidentCountryWarning, setIncidentCountryWarning ] = useState(false)
    const [ incidentDetailsWarning, setIncidentDetailsWarning ] = useState(false)
    const [ wFirst, setWFirst ] = useState()
    const [ wLast, setWLast ] = useState()
    const [ wAddress1, setWAddress1 ] = useState()
    const [ wAddress2, setWAddress2 ] = useState()
    const [ wTown, setWTown ] = useState()
    const [ wCounty, setWCounty ] = useState()
    const [ wPostCode, setWPostCode ] = useState()
    const [ wCountry, setWCountry ] = useState()
    const [ wPhone, setWPhone ] = useState()
    const [ description, setDescription ] = useState()
    const [ injuries, setInjuries ] = useState('No')  
    const [ descriptionWarning, setDescriptionWarning] = useState(false)
    const [ iDescription, setIDescription ] = useState()
    const [ display, setDisplay ] = useState([])
    const [ indexState, setIndexState ] = useState(0)
    const [ files, setFiles ] = useState([])
    const [ fileCount, setFileCount ] = useState(0)
    const [ source, setSource ] = useState([])
    const [ wEmail, setWEmail ] = useState()
    const [ additional, setAdditional ] = useState()
    const [ follow, setFollow ] = useState() 
    const [ checkSubmit, setCheckSubmit ] = useState()

    useEffect(()=>{
        let oNames = people
        UserService.getOnboardedNames().then(
            response =>{
                console.log(response.data)
                response.data?.map( row =>{
                    oNames.push({value: row.name, label: row.name})
                })
            }
        )
        setNames(oNames)
    },[])
    

    const handleHour = (e) =>{
        setHourWarning(false)
        if(e.target.value?.toString().length <= 2){
            setHour(e.target.value)
        }
        if(e.target.value > 23 || e.target.value < 0){
            setHourWarning(true)
        } 
        if(minutes){
            setTimeWarning(false)
        }
    }


    const handleMinutes = (e) =>{
        setMinutesWarning(false)
        if(e.target.value?.toString().length <= 2){
            setMinutes(e.target.value)
        }
        if(e.target.value > 59 || e.target.value < 0){
            setMinutesWarning(true)
        } 
        if(hour){
            setTimeWarning(false)
        }
    }


    const handleNext1 = () =>{
        if(!date || !name1 || (!minutes || !hour)){
            if(!name1){
                setNameWarning(true)
            }
            if(!date){
                setDateWarning(true)
            }
            if(!minutes || !hour){
                setTimeWarning(true)
            }
        }else{setStep(step+1)}
    }


    const handleNext2 = () =>{
        if(cat == 'first' || cat == 'second'){
            if(!name2){
                setName2Warning(true)
            }
            if(!address1){
                setAddress1Warning(true)
            }
            if(!town){
                setTownWarning(true)
            }
            if(!county){
                setCountyWarning(true)
            }
            if(!postCode){
                setPostCodeWarning(true)
            }
            if(!email){
                setEmailWarning(true)
            }
            if(!phone){
                setPhoneWarning(true)
            }
            if(name2 && address1 && town && county && postCode && email && phone){
                setStep(step+1)
            }
        }else{
            if(!first){
                setFirstWarning(true)
            }
            if(!last){
                setLastWarning(true)
            }
            if(!address1){
                setAddress1Warning(true)
            }
            if(!town){
                setTownWarning(true)
            }
            if(!county){
                setCountyWarning(true)
            }
            if(!postCode){
                setPostCodeWarning(true)
            }
            if(!country){
                setCountryWarning(true)
            }
            if(!email){
                setEmailWarning(true)
            }
            if(!phone){
                setPhoneWarning(true)
            }
            if(first && last && address1 && town && county && postCode && country && email && phone){
                setStep(step+1)
            }
        }
    }


    const dateChange = (e) =>{
        setDateWarning(false)
        setDate(e)
    }

    const handleName1 = (e) =>{
        setName1(e.value)
        setNameWarning(false)
    }

    const handleName2 = (e) =>{
        setName2(e.value)
        setAddress1()
        setAddress2()
        setTown()
        setCounty()
        setPostCode()
        setEmail()
        setPhone()
        let dat = {name: e.value}
        UserService.getAddress(dat).then(
            response => {
                console.log(response.data)
                setAddress1(response.data?.address1)
                setAddress2(response.data?.address2)
                setTown(response.data?.town)
                setCounty(response.data?.county)
                setPostCode(response.data?.post_code)
                setEmail(response.data?.email)
                setPhone(response.data?.phone)
            }
        )
        setName2Warning(false)
    } 


    useEffect(()=>{
        setFirst()
        setLast()
        setName2()
        setAddress1()
        setAddress2()
        setTown()
        setCounty()
        setPostCode()
        setEmail()
        setPhone()
    },[cat])

    useEffect(()=>{
        if(first && last){
            setName2(first+' '+last)
        }
    },[first, last])

    useEffect(()=>{
        if(address1){
            setAddress1Warning(false)
        }
    },[address1])

    useEffect(()=>{
        if(town){
            setTownWarning(false)
        }
    },[town])
    
    useEffect(()=>{
        if(county){
            setCountyWarning(false)
        }
    },[county])

    useEffect(()=>{
        if(postCode){
            setPostCodeWarning(false)
        }
    },[postCode])

    useEffect(()=>{
        if(email){
            setEmailWarning(false)
        }
    },[email])

    useEffect(()=>{
        if(phone){
            setPhoneWarning(false)
        }
    },[phone])

    useEffect(()=>{
        if(first){
            setFirstWarning(false)
        }
    },[first])
    
    useEffect(()=>{
        if(last){
            setLastWarning(false)
        }
    },[last])

    useEffect(()=>{
        if(country){
            setCountryWarning(false)
        }
    },[country])


    const handlePrevious = () =>{
        setStep(step-1)
    }

    const handleNext3 = () =>{
        if(!incidentAddress1){
            setIncidentAddress1Warning(true)
        }
        if(!incidentTown){
            setIncidentTownWarning(true)
        }
        if(!incidentCounty){
            setIncidentCountyWarning(true)
        }
        if(!incidentPostCode){
            setIncidentPostCodeWarning(true)
        }
        if(!incidentCountry){
            setIncidentCountryWarning(true)
        }
        if(!incidentDetails){
            setIncidentDetailsWarning(true)
        }
        if(incidentAddress1 && incidentTown && incidentCounty && incidentPostCode && incidentCountry && incidentDetails){
            setStep(step+1)
        }
    }

    useEffect(()=>{
        if(incidentAddress1){
            setIncidentAddress1Warning(false)
        }
    },[incidentAddress1])

    useEffect(()=>{
        if(incidentTown){
            setIncidentTownWarning(false)
        }
    },[incidentTown])

    useEffect(()=>{
        if(incidentCounty){
            setIncidentCountyWarning(false)
        }
    },[incidentCounty])

    useEffect(()=>{
        if(incidentPostCode){
            setIncidentPostCodeWarning(false)
        }
    },[incidentPostCode])

    useEffect(()=>{
        if(incidentCountry){
            setIncidentCountryWarning(false)
        }
    },[incidentCountry])

    useEffect(()=>{
        if(incidentDetails){
            setIncidentDetailsWarning(false)
        }
    },[incidentDetails])

    const handleNext4 = () =>{
    
            setStep(step+1)
      
    }
    const handleNext5 = () =>{
        if(!description){
            setDescriptionWarning(true)
        }else{
            setStep(step+1)
        }
    }
    useEffect(()=>{
        if(description){
            setDescriptionWarning(false)
        }
    },[description])


    let index = 0
    const handleCapture = (target) => {
        //setFiles(oldFiles =>[...oldFiles, target.files[0]])
        setFiles(oldFiles => [...oldFiles, target.files[0]])   
        index = indexState
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];

                console.log(target.files[0])
                const newUrl = URL.createObjectURL(file);
                console.log(newUrl)
                //setSource(oldSource =>[...oldSource, newUrl]);
                source.push(newUrl)
                    let element = <img src={newUrl} alt={"snap"} className="picture"></img>
                                     
                    const data =  [...display, element]                        
                    setDisplay(data)
                    setIndexState(indexState+1)
                    setFileCount(fileCount+1)                    
            }
        }
       
    }

    useEffect(() =>{
        console.log(files)
    },[files])
    const handleSubmit = () =>{
        let formData = new FormData();
        formData.append('date', date)
        formData.append('time', hour+':'+minutes)
        formData.append('cat', cat)
        formData.append('name', name2)
        formData.append('address1', address1)
        formData.append('address2', address2)
        formData.append('town', town)
        formData.append('county', county)
        formData.append('postCode', postCode)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('wName', wFirst+' '+wLast)
        formData.append('wAddress1', wAddress1)
        formData.append('wAddress2', wAddress2)
        formData.append('wTown', wTown)
        formData.append('wCounty', wCounty)
        formData.append('wPostCode', wPostCode)
        formData.append('wEmail', wEmail)
        formData.append('wPhone', wPhone)
        formData.append('iAddress1', incidentAddress1)
        formData.append('iAddress2', incidentAddress2)
        formData.append('iTown', incidentTown)
        formData.append('iCounty', incidentCounty)
        formData.append('iPostCode', incidentPostCode)
        formData.append('iDetails', incidentDetails)
        formData.append('description', description)
        formData.append('injuriesSustained', injuries)
        formData.append('iDescription', iDescription)
        formData.append('additional', additional)
        formData.append('follow', follow)
        formData.append('updatedBy', name1)
        
        
        for(let i = 0; i < files.length; i++){
            console.log(typeof(files[i]))
            formData.append("filename", i)
            formData.append('file'+i, files[i])
        }
        UserService.postIncidentForm(formData).then(
            response => {
                console.log(response)
                if(response.status == 200){
                    setCheckSubmit("Thank you for submitting.")
                }
            }
        ).catch(err =>  setCheckSubmit("Something went wrong. Please refresh and try again. If the issue persists please contact the office."))
        
        for(var pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
          }
    }
    return(
        <div className="incidentWrap incidentArch">
            <h1 className="h1Profile">Incident Report</h1>
            {!checkSubmit ?
            <div className="stepper">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={step} alternativeLabel>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </Box>
            </div> : ''
            }
            {step == 0 ? 
                <>
                    <div className="calendarIR">
                        <label for="Calendar" className="label2">Date of Incident <span style={{color: '#b30000'}}>*</span></label>
                        <p className="pClass">Select the Date the incident occurred</p>
                        <Calendar  
                            onChange={e => dateChange(e)} 
                            style={{marginLeft: '200px'}}
                            //value={date? date : new Date()} 
                            validRange = {{
                                start: new Date(),
                                end: null
                            }}
                            
                            className={'calendarX'}
                        />
                        {dateWarning == true ? 
                            <p className="pClass" style={{ color: '#ff3333'}}>Please select a date!</p> : ''
                        }
                    </div>
                    <div>
                        <label for="Calendar" className="label2">Time of Incident <span style={{color: '#b30000'}}>*</span></label>
                        <p className="pClass">What time did the incident occur</p>
                        <div className="flex">
                            <input type="number" placeholder="HH" min={1} max={24} onChange={e => handleHour(e)} value={hour} ></input>  :  
                            <input type="number" placeholder="MM" min={0} max={59} onChange={e => handleMinutes(e)} value={minutes}></input>
                        </div>
                        {hourWarning == true ? 
                            <p className="pClass" style={{ color: '#ff3333'}}>The hour must be between 00 and 23!</p> : ''
                        }
                        {minutesWarning == true ? 
                            <p className="pClass" style={{ color: '#ff3333'}}>The minutes must be between 00 and 59!</p> : ''
                        }
                        {timeWarning == true ? 
                            <p className="pClass" style={{ color: '#ff3333'}}>Please complete the time!</p> : ''
                        }
                    </div>
                    <div >
                        <label for="complete" className="label2">Completed By <span style={{color: '#b30000'}}>*</span></label>
                        <Select 
                            options={names ? names : ''}
                            styles={colourStyles}
                            //value={{label: status, value: status}}
                            onChange={e => handleName1(e)}
                        /> 
                        {nameWarning == true ? 
                            <p className="pClass" style={{ color: '#ff3333'}}>Please select a name!</p> : ''
                        }
                    </div>
                </> :
                step == 1 ? 
                    <>
                        <div className="category">
                            <label for="Calendar" className="label2">Category of Person Involved <span style={{color: '#b30000'}}>*</span></label>
                            <p className="pClass">Select which category the person involved best matches</p>
                        </div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="third"
                                name="radio-buttons-group"
                                onChange={e => setCat(e.target.value)}
                            >
                                <FormControlLabel value="first" control={<Radio />} label="Employee of the company" />
                                <FormControlLabel value="second" control={<Radio />} label="Contractor for the company" />
                                <FormControlLabel value="third" control={<Radio />} label="Third Party" />
                            </RadioGroup>
                        </FormControl>
                        {cat == 'first' || cat == 'second' ?
                            <>
                                <div className="flex">
                                    <div className="flexComp">
                                            <label for="name" className="label2 ">Employee/Contractors Name <span style={{color: '#b30000'}}>*</span></label>
                                            <Select 
                                                options={names ? names : ''}
                                                styles={colourStyles}
                                                //value={{label: status, value: status}}
                                                onChange={e => handleName2(e)}
                                            /> 
                                    </div>
                                </div>
                                {name2Warning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Please select a name!</p> : ''
                                }
                                <div className="flex">
                                    <div className="flexComp">
                                        <label for="address1" className="label2">Address#1 <span style={{color: '#b30000'}}>*</span></label>
                                        <input type="text" name="address1" className="inputEdit" value={address1 ? address1 : ''} onChange={e => setAddress1(e.target.value)}></input>
                                    </div>
                                    <div className="flexComp">
                                        <label for="address2" className="label2 correction">Address#2 </label>
                                        <input type="text" name="address2" className="inputEdit correction" value={address2 ? address2 : ''} onChange={e => setAddress2(e.target.value)}></input>
                                    </div>
                                </div>
                                {address1Warning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Address missing!</p> : ''
                                }
                                <div className="flex">
                                    <div className="flexComp">
                                            <label for="town" className="label2 ">Town/City <span style={{color: '#b30000'}}>*</span></label>
                                            <input type="text" name="town" className="lone " value={town ? town : ''} onChange={e => setTown(e.target.value)}></input>
                                    </div>
                                </div>
                                {townWarning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Town missing!</p> : ''
                                }
                                <div className="flex">
                                    <div className="flexComp">
                                        <label for="county" className="label2">County <span style={{color: '#b30000'}}>*</span></label>
                                        <input type="text" name="county" className="inputEdit" value={county ? county : ''} onChange={e => setCounty(e.target.value)}></input>
                                    </div>
                                    <div className="flexComp">
                                        <label for="postCode" className="label2 correction">Post Code <span style={{color: '#b30000'}}>*</span></label>
                                        <input type="text" name="postCode" className="inputEdit correction" value={postCode ? postCode :''} onChange={e => setPostCode(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                        {countyWarning == true ? 
                                            <p className="pClass topC" style={{ color: '#ff3333'}}>County missing!</p> : ''
                                        }
                                    </div>
                                    <div className="flexComp">
                                        {postCodeWarning == true ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Post code missing!</p> : ''
                                        }
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                        <label for="email" className="label2">Email <span style={{color: '#b30000'}}>*</span></label>
                                        <input type="text" name="email" className="inputEdit" value={email ? email :''} onChange={e => setEmail(e.target.value)}></input>
                                    </div>
                                    <div className="flexComp">
                                        <label for="phone" className="label2 correction">Phone No <span style={{color: '#b30000'}}>*</span></label>
                                        <input type="text" name="phone" className="inputEdit correction" value={phone ? phone : ''} onChange={e => setPhone(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                        {emailWarning == true ? 
                                            <p className="pClass topC" style={{ color: '#ff3333'}}>Email missing!</p> : ''
                                        }
                                    </div>
                                    <div className="flexComp">
                                        {phoneWarning == true ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Phone missing!</p> : ''
                                        }
                                    </div>
                                </div>
                            </> : 
                            <>
                                <label  className="label2">Name <span style={{color: '#b30000'}}>*</span></label>
                                <div className="flex">
                                    <div className="flexComp">
                                        <p className="pClass">First Name</p>
                                        <input type="text" className="inputEdit" placeholder=" "value={first ? first : ''} onChange={e => setFirst(e.target.value)}></input>
                                    </div>
                                    <div className="flexComp">
                                        <p className="pClass pCorrection">Last Name</p>
                                        <input type="text" className="inputEdit correction" value={last ? last :''} onChange={e => setLast(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                        {firstWarning == true ? 
                                            <p className="pClass topC" style={{ color: '#ff3333'}}>First name missing!</p> : ''
                                        }
                                    </div>
                                    <div className="flexComp">
                                        {lastWarning == true ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Last name missing!</p> : ''
                                        }
                                    </div>
                                </div>
                                <label  className="label2">Address <span style={{color: '#b30000'}}>*</span></label>
                                <div className="flex">
                                    <div className="flexComp">
                                            <p className="pClass">Name/Number and Street</p>
                                            <input type="text" className="lone " value={address1 ? address1 : ''} onChange={e => setAddress1(e.target.value)}></input>
                                    </div>
                                </div>
                                {address1Warning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Name/Number and Street missing!</p> : ''
                                }
                                <div className="flex topC">
                                    <div className="flexComp">
                                            <p className="pClass">Address Line 2</p>
                                            <input type="text" className="lone " value={address2 ? address2 : ''} onChange={e => setAddress2(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="flex topC">
                                    <div className="flexComp">
                                        <p className="pClass">Town/City</p>
                                        <input type="text" className="inputEdit" value={town ? town : ''} onChange={e => setTown(e.target.value)}></input>
                                    </div>
                                    <div className="flexComp">
                                        <p className="pClass pCorrection">County / State / Region</p>
                                        <input type="text" className="inputEdit correction" value={county ? county :''} onChange={e => setCounty(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                        {townWarning == true ? 
                                            <p className="pClass topC" style={{ color: '#ff3333'}}>Town missing!</p> : ''
                                        }
                                    </div>
                                    <div className="flexComp">
                                        {countyWarning == true ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>County / State / Region missing!</p> : ''
                                        }
                                    </div>
                                </div>
                                <div className="flex topC">
                                    <div className="flexComp">
                                        <p className="pClass">Zip / Postal Code</p>
                                        <input type="text" className="inputEdit" value={postCode ? postCode : ''} onChange={e => setPostCode(e.target.value)}></input>
                                    </div>
                                    <div className="flexComp">
                                        <p className="pClass pCorrection">Country</p>
                                        <Select 
                                            options={Countries}
                                            styles={colourStylesR}
                                            //value={{label: status, value: status}}
                                            onChange={e => setCountry(e.value)}
                                        /> 
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                        {postCodeWarning == true ? 
                                            <p className="pClass topC" style={{ color: '#ff3333'}}>Zip / Postal Code missing!</p> : ''
                                        }
                                    </div>
                                    <div className="flexComp">
                                        {countryWarning == true ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Country missing!</p> : ''
                                        }
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flexComp">
                                            <label for="email" className="label2 ">Email <span style={{color: '#b30000'}}>*</span></label>
                                            <input type="text" name="email" className="lone " value={email ? email : ''} onChange={e => setEmail(e.target.value)}></input>
                                    </div>
                                </div>
                                {emailWarning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Email missing!</p> : ''
                                }
                                <div className="flex">
                                    <div className="flexComp">
                                            <label for="phone" className="label2 ">Contact Number <span style={{color: '#b30000'}}>*</span></label>
                                            <input type="text" name="phone" className="lone " value={phone ? phone : ''} onChange={e => setPhone(e.target.value)}></input>
                                    </div>
                                </div>
                                {phoneWarning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Phone missing!</p> : ''
                                }
                            </>
                        }
                    </> 
                : step == 2 ? 
                    <>
                        <h3 className="subttH3">Location Details</h3>
                        <label  className="label2">Address of Incident <span style={{color: '#b30000'}}>*</span></label>
                        <div className="flex">
                            <div className="flexComp">
                                    <p className="pClass">Name/Number and Street</p>
                                    <input type="text" className="lone " value={incidentAddress1 ? incidentAddress1 : ''} onChange={e => setIncidentAddress1(e.target.value)}></input>
                            </div>
                        </div>
                        {incidentAddress1Warning == true ? 
                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Name/Number and Street missing!</p> : ''
                        }
                        <div className="flex topC">
                            <div className="flexComp">
                                    <p className="pClass">Address Line 2</p>
                                    <input type="text" className="lone " value={incidentAddress2 ? incidentAddress2 : ''} onChange={e => setIncidentAddress2(e.target.value)}></input>
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                <p className="pClass">Town/City</p>
                                <input type="text" className="inputEdit" value={incidentTown ? incidentTown : ''} onChange={e => setIncidentTown(e.target.value)}></input>
                            </div>
                            <div className="flexComp">
                                <p className="pClass pCorrection">County / State / Region</p>
                                <input type="text" className="inputEdit correction" value={incidentCounty ? incidentCounty :''} onChange={e => setIncidentCounty(e.target.value)}></input>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flexComp">
                                {incidentTownWarning == true ? 
                                    <p className="pClass topC" style={{ color: '#ff3333'}}>Town/City missing!</p> : ''
                                }
                            </div>
                            <div className="flexComp">
                                {incidentCountyWarning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>County / State / Region missing!</p> : ''
                                }
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                <p className="pClass">Zip / Postal Code</p>
                                <input type="text" className="inputEdit" value={incidentPostCode ? incidentPostCode : ''} onChange={e => setIncidentPostCode(e.target.value)}></input>
                            </div>
                            <div className="flexComp">
                                <p className="pClass pCorrection">Country</p>
                                <Select 
                                    options={Countries}
                                    styles={colourStylesR}
                                    //value={{label: status, value: status}}
                                    onChange={e => setIncidentCountry(e.value)}
                                /> 
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flexComp">
                                {incidentPostCodeWarning== true ? 
                                    <p className="pClass topC" style={{ color: '#ff3333'}}>Zip / Postal Code missing!</p> : ''
                                }
                            </div>
                            <div className="flexComp">
                                {incidentCountryWarning == true ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Country missing!</p> : ''
                                }
                            </div>
                        </div>
                        <label  className="label2">Details/Description of Location <span style={{color: '#b30000'}}>*</span></label>
                        <div className="flex">
                            <div className="flexComp">
                                    <textarea className="lone "rows={10} value={incidentDetails ? incidentDetails : ''} onChange={e => setIncidentDetails(e.target.value)}></textarea>
                            </div>
                        </div>
                        {incidentDetailsWarning == true ? 
                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Details are missing!</p> : ''
                        }
                    </> :
                step == 3 ?
                    <>
                        <h3 className="subttH3">Witness Details</h3>
                        <label  className="label2">Name </label>
                        <div className="flex">
                            <div className="flexComp">
                                <p className="pClass">First</p>
                                <input type="text"  className="inputEdit" value={wFirst ? wFirst : ''} onChange={e => setWFirst(e.target.value)}></input>
                            </div>
                            <div className="flexComp">
                                <p className="pClass pCorrection">Last</p>
                                <input type="text"  className="inputEdit correction" value={wLast ? wLast : ''} onChange={e => setWLast(e.target.value)}></input>
                            </div>
                        </div>
                        <label  className="label2">Address </label>
                        <div className="flex topC">
                            <div className="flexComp">
                                    <p className="pClass">Name/Number and Street</p>
                                    <input type="text" className="lone " value={wAddress1 ? wAddress1 : ''} onChange={e => setWAddress1(e.target.value)}></input>
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                    <p className="pClass">Address Line 2</p>
                                    <input type="text" className="lone " value={wAddress2 ? wAddress2 : ''} onChange={e => setWAddress2(e.target.value)}></input>
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                <p className="pClass">Town/City</p>
                                <input type="text"  className="inputEdit" value={wTown ? wTown : ''} onChange={e => setWTown(e.target.value)}></input>
                            </div>
                            <div className="flexComp">
                                <p className="pClass pCorrection">County / State / Region</p>
                                <input type="text"  className="inputEdit correction" value={wCounty ? wCounty : ''} onChange={e => setWCounty(e.target.value)}></input>
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                <p className="pClass">ZIP / Postal Code</p>
                                <input type="text"  className="inputEdit" value={wPostCode ? wPostCode : ''} onChange={e => setWPostCode(e.target.value)}></input>
                            </div>
                            <div className="flexComp">
                                <p className="pClass pCorrection">Country</p>
                                <Select 
                                    options={Countries}
                                    styles={colourStylesR}
                                    //value={{label: status, value: status}}
                                    onChange={e => setWCountry(e.value)}
                                />                             
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                <p className="pClass">Witness Contact No</p>
                                <input type="text"  className="inputEdit" value={wPhone ? wPhone : ''} onChange={e => setWPhone(e.target.value)}></input>
                            </div>
                            <div className="flexComp">
                                <p className="pClass pCorrection">Witness Contact Email</p>
                                <input type="text"  className="inputEdit correction" value={wEmail ? wEmail : ''} onChange={e => setWEmail(e.target.value)}></input>
                            </div>
                        </div>
                        
                    </>  :
                step == 4 ?
                    <>
                        <h3 className="subttH3">Description of Incident</h3>
                        <p className="pClass">If in the event of vehicle collision please enter the third parties details in the Additional Information section</p>
                        <div className="flex">
                            <div className="flexComp">
                                    <label for="description" className="label2 ">Description <span style={{color: '#b30000'}}>*</span></label>
                                    <textarea type="text" name="description" rows={10} className="lone " value={description ? description : ''} onChange={e => setDescription(e.target.value)}></textarea>
                            </div>
                            {descriptionWarning == true ? 
                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>Description is missing!</p> : ''
                            }
                        </div>
                        <div className="pCorrection">
                            <label for="description" className="label2 ">Injuries Sustained <span style={{color: '#b30000'}}>*</span></label>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="No"
                                    name="radio-buttons-group"
                                    onChange={e => setInjuries(e.target.value)}
                                >
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {injuries == 'Yes' ? 
                            <div className="pCorrection">
                                <label for="description" className="label2 ">Details of Injuries and Who </label>
                                <p className="pClass">Please record known injuries received and on who</p>
                                <textarea type="text" name="description" rows={10} className="lone " value={iDescription ? iDescription : ''} onChange={e => setIDescription(e.target.value)}></textarea>

                            </div> : ''
                        }
                    </> :   
                    !checkSubmit && step == 5 ?
                    <>
                        <h3 className="subttH3">Additional Information</h3>
                        <label className="label2 ">Additional Information</label>
                        <textarea type="text" rows={10} className="lone " value={additional ? additional : ''} onChange={e => setAdditional(e.target.value)}></textarea>
                        <label className="label2 ">Pictures</label>
                        <p className="pClass">Please take or upload pictures below</p>
                        <div className="picturesIncidentDiv">
                            <div className="flex">
                                <div className="takePictureIncident">
                                    <input
                                        accept="image/*"
                                        //className={classes.input}
                                        style={{display: 'none'}}
                                        id="icon-button-file"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => handleCapture(e.target)}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <CameraIcon className="incidentCamera" />
                                        <label className="label2 ">Take Pictures</label>
                                    </label>
                                </div>
                                {/*<div className="takePictureIncident">
                                    <UploadFileIcon className="incidentCamera" />
                                    <label className="label2 ">Upload Pictures</label>
                                </div>*/}
                            </div>
                            <div className="displayGridPic">
                                {display.length > 0 ? display : ''}
                            </div>
                        </div>
                        
                        <label className="label2 ">Follow Up Comments</label>
                        <textarea type="text" rows={10} className="lone " value={follow ? follow : ''} onChange={e => setFollow(e.target.value)}></textarea>

                    </>: 
                    <h3 className="thank">{checkSubmit}</h3>
            }
            {
                step == 0 ?
                    
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft: '92.5%'}} onClick={handleNext1}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Next</span>
                        <div class="fill-container"></div>
                    </button> :
                step == 1 ? 
                    <div className="flex" style={{marginLeft: '82%'}}> 
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Previous</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleNext2}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Next</span>
                            <div class="fill-container"></div>
                        </button>
                    </div> : 
                step == 2 ? 
                <div className="flex" style={{marginLeft: '82%'}}> 
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Previous</span>
                        <div class="fill-container"></div>
                    </button>
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleNext3}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Next</span>
                        <div class="fill-container"></div>
                    </button>
                </div> : 
                step == 3 ?
                <div className="flex" style={{marginLeft: '82%'}}> 
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Previous</span>
                        <div class="fill-container"></div>
                    </button>
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleNext4}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Next</span>
                        <div class="fill-container"></div>
                    </button>
                </div> : 
                step == 4 ? 
                <div className="flex" style={{marginLeft: '82%'}}> 
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Previous</span>
                        <div class="fill-container"></div>
                    </button>
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleNext5}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Next</span>
                        <div class="fill-container"></div>
                    </button>
                </div> : 
                step == 5 && !checkSubmit ?
                <div className="flex" style={{marginLeft: '82%'}}> 
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handlePrevious}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Previous</span>
                        <div class="fill-container"></div>
                    </button>
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                        <div class="fill-container"></div>
                    </button>
                </div>: ''
            }
        </div>
    )
}
export default IncidentForm;