import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import AuthService from "../../services/auth.service";
import countries from "./countryes";


const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const PersonalDetails = () =>{
    const user = AuthService.getCurrentUser();
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ emailConf, setEmailConf ] = useState()
    const [ postCode, setPostCode ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ county, setCounty ] = useState()
    const [ country, setCountry ] = useState()
    const [ source, setSource ] = useState()
    const [ countrySC, setCountrySC ] = useState()
    const [ dlNo, setDlNo ] = useState()
    const [ dlExp, setDlExp ] = useState()
    const [ checked, setChecked ] = useState()
    const [ warningConf, setWarningConf ] = useState()
    const [ check, setCheck ] = useState(false)
    const [ confMessage, setConfMEssage ] = useState("")


    useEffect(()=>{
        let data = {name: user.username}
        UserService.getDashData(data).then(
            response =>{
                let data = response.data
                setSource(data)
                console.log(response.data)
                setPhone(data.phone)
                setEmail(data.email)
                setEmailConf(data.email)
                setPostCode(data.post_code)
                setAddress1(data.address1)
                setAddress2(data.address2)
                setTown(data.town)
                setCounty(data.county)
                setDlNo(data.dl_no)
                setDlExp(data.dl_expiry)
            }
        ).catch(err => console.log(err))
    },[])
    useEffect(()=>{
        countries.map(element =>{
            if(source?.dl_origin == element.value){
                setCountry(element)
                setCountrySC(element.value)
            }
        })
    },[source])

    const handlePhone = (e) =>{
        setPhone(e.target.value)
    }
    const handleEmail = (e) =>{
        setEmail(e.target.value)
        if(e.target.value !== emailConf){
            setWarningConf("confirmation email different than email")
        }else{
            setWarningConf("")
        }
    }
    const handlePostCode = (e) =>{
        setPostCode(e.target.value)
    }
    const handleAddress1 = (e) =>{
        setAddress1(e.target.value)
    }
    const handleAddress2 = (e) =>{
        setAddress2(e.target.value)
    }
    const handleTown = (e) =>{
        setTown(e.target.value)
    }
    const handleCounty = (e) =>{
        setCounty(e.target.value)
    }
    const handleEmailConf = (e) =>{
        setEmailConf(e.target.value)
        if(email !== e.target.value){
            setWarningConf("confirmation email different than email")
        }else{
            setWarningConf("")
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = () =>{
        let data = {name: user.username, phone: phone, email: email, post_code: postCode, address1: address1, address2: address2, town: town, county: county, dl_origin: countrySC, dl_no: dlNo, dl_expiry: dlExp, updatedBy: user.username}
        UserService.updatePDetails(data).then(
            response =>{
                console.log(response)
                setCheck(true)
                setConfMEssage(<h3 style={{color: 'green'}}>Personal data updated!</h3>)
                sleep(3000).then(()=>{
                    setCheck(false)
                    window.location.reload(false)
                })
            }
        ).catch(err => {
            console.log(err)
            setConfMEssage(<h3 style={{color: 'red'}}>Something went wrong, please refresh and try again!</h3>)
        })
    }

    return(
        
        <>
            {!check ? 
            <>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Phone <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                            <input type="text" className="lonePm" value={phone ? phone : ''} onChange={e => handlePhone(e)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                    <div className="flexComp">
                    </div>
                </div>
                <div className="flex ">
                    <div className="flexComp">
                        <label  className="label2">Email </label>
                        <input type="text" className="inputRPmX" value={email ? email : ''} onChange={e => handleEmail(e)}></input>
                        <p className="pClass">Enter Email</p>
                        {/*warningTotal ? <p className="warning">{warningTotal}</p> : ''*/}
                    </div>
                    <div className="flexComp">
                        <label  className="label2" style={{color: 'white'}}> t</label>
                        <input type="text" className="inputRPmX" value={emailConf ? emailConf : ''}  onChange={e => handleEmailConf(e)}></input>
                        <p className="pClass">Confirm Email</p>
                        {warningConf ? <p className="warning">{warningConf}</p> : ''}
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Address </label>
                            <input type="text" className="lonePmX" value={postCode ? postCode : ''} onChange={e => handlePostCode(e)} ></input>
                            <p className="pClass">Zip / Postal Code</p>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                    <div className="flexComp">
                    </div>
                </div>
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} /*onClick={handleLookup}*/>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Lookup</span>
                    <div class="fill-container"></div>
                {/*<button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={handleLookup}>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Lookup</span>
                    <div class="fill-container"></div>
                </button>*/}
                </button>
                <div className="flex" style={{marginTop: '20px'}}>
                    <div className="flexComp">
                            <input type="text" className="lonePm" value={address1 ? address1 :''} onChange={e => handleAddress1(e)}  style={{height: '50%'}}></input>
                            <p className="pClass">Name/Number and Street</p>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <div className="flex" style={{marginTop: '20px'}}>
                    <div className="flexComp">
                            <input type="text" className="lonePm" value={address2 ? address2 : ''} onChange={e => handleAddress2(e)}  style={{height: '50%'}}></input>
                            <p className="pClass">Address Line 2</p>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <div className="flex " style={{marginTop: '20px'}}>
                    <div className="flexComp">
                        <input type="text" className="inputRPmX" value={town ? town : ''} onChange={e => handleTown(e)} style={{height: '50%'}}></input>
                        <p className="pClass">Town/City</p>
                        {/*warningTotal ? <p className="warning">{warningTotal}</p> : ''*/}
                    </div>
                    <div className="flexComp">
                        <input type="text" className="inputRPmX" value={county ? county : ''}  onChange={e => handleCounty(e)} style={{height: '50%'}}></input>
                        <p className="pClass">County / State / Region</p>
                        {/*warningDepotR ? <p className="warning">{warningDepotR}</p> : ''*/}
                    </div>
                </div>
                <h3 className="h3Cap"><b>Driving Licence Details</b></h3>
                <div className="flex topC" style={{marginTop: '5px'}}>
                    <div className="flexComp">
                        <label  className="label2">Country Issued</label>
                        <Select 
                            options={countries}
                            styles={colourStyles}
                            value={country ? country : ''}
                            onChange={e => {
                                setCountrySC(e.value)
                                countries.map(element =>{
                                    if(e.value == element.value){
                                        setCountry(element)
                                    }
                                })
                            }}
                        />                     </div>
                    <div className="flexComp">
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Country Short Code <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                            <input type="text" className="lonePm" value={countrySC ? countrySC : ''} onChange={e => setCountrySC(e.target.value)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                    <div className="flexComp">
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Driving Licence No</label>
                            <input type="text" className="lonePm" value={dlNo ? dlNo :''} onChange={e => setDlNo(e.target.value)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                    <div className="flexComp">
                    </div>
                </div>
                <div className="flex topC" style={{marginTop: '5px'}}>
                    <div className="flexComp">
                        <label  className="label2">Driving Licence Expiry Date <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                        <input type="date" className="inputEdit " value={dlExp ? dlExp :''} onChange={e => setDlExp(e.target.value)}></input>
                        </div>
                    <div className="flexComp">
                    </div>
                </div>
                <div className="flex" style={{marginTop: '20px'}}>
                    <div className="flexComp">
                            <label  className="label2">Confirmation <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                            <div className="flex">
                                <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" onChange={e => setChecked(e.target.checked)}/>
                                <label for="vehicle3" style={{marginLeft: '5px', color: 'gray'}}> I confirm the details entered are correct</label>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                            </div>
                    </div>
                </div>
                <div className="lastDiv">
                    <p className="pClass">You must check and confirm the details are correct before you can submit them.</p>
                    <p className="pClass">All entries are made at your own risk, Skilled Solutions hold no liability for any errors that occur as a result of incorrect information</p>
                </div>
                {checked ? 
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                        <div class="fill-container"></div>
                        {/*<button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={handleSubmit}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                            <div class="fill-container"></div>
                        </button>*/}
                    </button>
                    
                    : ''
                }
            </>: <>{confMessage}</>
            }
        </>
    )
}
export default PersonalDetails;