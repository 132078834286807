import React from "react";
import { useState, useEffect, useRef } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from "axios";
import PersonalDetails from "./personalDetails.component";
import Company from "./company.component";
import Bank from "./bank.component";


const Dash = () => {
    const [ value, setValue ] = useState('1')
   
    //const api = new Client("SujDJW-9YkqykE4rXffRlA43323");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <div className="dashArch">

            <Box sx={{ width: '100%', typography: 'body1', marginLeft: '2%', marginTop:'2%' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable"
  scrollButtons="on">
                        
                        <Tab label="Personal Details" value="1" />
                        <Tab label="Company / Tax Details" value="2" />
                        <Tab label="Bank Details" value="3" />
                        <Tab label="Payment Summary" value="4" />
                    </TabList>
                    </Box>
                        <TabPanel value="1">
                            <PersonalDetails />                
                        </TabPanel>
                        <TabPanel value="2">
                            <Company />                
                        </TabPanel>
                        <TabPanel value="3">
                            <Bank />                
                        </TabPanel>
                </TabContext>
            </Box>    
        </div>
    )
}
export default Dash;