import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined';
import BlurCircularOutlinedIcon from '@mui/icons-material/BlurCircularOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import TollIcon from '@mui/icons-material/Toll';
import axios from "axios";
import Picture from "./badge.component";
import pdfImg from './img/pdfIcon.png';
import importPdf from './img/importPdf.png'
//import Tabs from '@material-ui/lab/Tabs';


const Profiles = () =>{
    const [ data, setData ] = useState()
    const [ stDisplay, setStDisplay ] = useState()
    const [ search, setSearch ] = useState()
    const [ searchVal, setSearchVal ] = useState()
    const [ pending, setPending ] = useState(false)
    const [ onboarding, setOnboarding ] = useState(false)
    const [ onboarded, setOnboarded ] = useState(false)
    const [ inactive, setInactive ] = useState(false)
    const [ offboarded, setOffboarded ] = useState(false)
    const [ open, setOpen ] = useState(false)
    const [ value, setValue ] = useState('1')
    const [ details, setDetails ] = useState()
    const [ loaded, setLoaded ] = useState(true)
    const [ src, setSrc ] = useState()
    const [ files, setFiles ] = useState([])
    const [ openPdf, setOpenPdf ] = useState(false)
    const [ pdfName, setPdfName ] = useState()
    const [ name, setName ] = useState()
    let displaiC = [];

    useEffect(() =>{
        UserService.getProfilesData().then(
            response =>{
                setData(response.data)
            }
        )
    },[])
    
    useEffect(()=>{
        data?.map((dataRow, index) => {
            displaiC.push(
                <Card sx={{ maxWidth: 345 ,margin: 1, padding: 2}}>
                    <CardActionArea>
                        <Picture link={`https://node.skso.co.uk:9040/documents/${dataRow.name}/badge.jpg`} />                       
                        <CardContent>
                        <Typography gutterBottom variant="h6" color="text.secondary">
                            Name: {dataRow.name}
                        </Typography>
                        <Typography gutterBottom variant="h6" color="text.secondary">
                            Station: {dataRow.station}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            Status: {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/} {dataRow.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : dataRow.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : dataRow.status == 'Active' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : dataRow.status == 'Inactive' ? <TollIcon style={{color: '#eb7a3e'}}/> : dataRow.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}  {dataRow.status}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary" onClick={e=>handleShow(dataRow)} >
                            Show more
                        </Button>
                    </CardActions>
                </Card> 
                ) 
       
        })
    setStDisplay(displaiC)  
    console.log(displaiC)
    },[data])

    const handleSearch = () =>{
        displaiC = []
        data?.map((dataRow, index) => {
            if(dataRow.name?.toLowerCase().match(searchVal.toLowerCase())){
                displaiC.push(
                    <Card sx={{ maxWidth: 345 ,margin: 1, padding: 2}}>
                        <CardActionArea>
                            <Picture link={`https://node.skso.co.uk:9040/documents/${dataRow.name}/badge.jpg`} />                      
                            <CardContent>
                            <Typography gutterBottom variant="h6" color="text.secondary">
                                Name: {dataRow.name}
                            </Typography>
                            <Typography gutterBottom variant="h6" color="text.secondary">
                                Station: {dataRow.station}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                Status: {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/} {dataRow.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : dataRow.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : dataRow.status == 'Active' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : dataRow.status == 'Inactive' ? <TollIcon style={{color: '#eb7a3e'}}/> : dataRow.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}  {dataRow.status}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={e=>handleShow(dataRow)} >
                                Show more
                            </Button>
                        </CardActions>
                    </Card> 
                    ) 
            }   
        })
        setStDisplay(displaiC) 
        
    }

    const handlePending = () =>{
        if(pending == false){
            setPending(true)
        }else{
            setPending(false)
        }
    }
    const handleOnboarding = () =>{
        if(onboarding == false){
            setOnboarding(true)
        }else{
            setOnboarding(false)
        }
    }
    const handleOnboarded = () =>{
        if(onboarded == false){
            setOnboarded(true)
        }else{
            setOnboarded(false)
        }
    }
    const handleInactive = () =>{
        if(inactive == false){
            setInactive(true)
        }else{
            setInactive(false)
        }
    }
    const handleOffboarded = () =>{
        if(offboarded == false){
            setOffboarded(true)
        }else{
            setOffboarded(false)
        }
    }

    useEffect(() =>{
        displaiC = []
        if(pending == false && onboarding == false && onboarded == false && inactive == false && offboarded == false){
            data?.map((dataRow, index) => {
                displaiC.push(
                    <Card sx={{ maxWidth: 345 ,margin: 1, padding: 2}}>
                        <CardActionArea>
                            <Picture link={`https://node.skso.co.uk:9040/documents/${dataRow.name}/badge.jpg`} />                        
                            <CardContent>
                            <Typography gutterBottom variant="h6" color="text.secondary">
                                Name: {dataRow.name}
                            </Typography>
                            <Typography gutterBottom variant="h6" color="text.secondary">
                                Station: {dataRow.station}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                Status: {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/} {dataRow.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : dataRow.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : dataRow.status == 'Active' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/>   : ''}  {dataRow.status}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={e=>handleShow(dataRow)} >
                                Show more
                            </Button>
                        </CardActions>
                    </Card> 
                    ) 
            })
        }
        
       

        data?.map((dataRow, index) => {
            

            if((pending == true && dataRow.status == 'Pending') || (onboarding == true && dataRow.status == 'Onboarding') || (onboarded == true && dataRow.status == 'Active')){
                
              
               
                
                
                displaiC.push(
                    <Card sx={{ maxWidth: 345 ,margin: 1, padding: 2}}>
                        <CardActionArea>
                            
                            <Picture link={`https://node.skso.co.uk:9040/documents/${dataRow.name}/badge.jpg`} />
                            <CardContent>
                            <Typography gutterBottom variant="h6" color="text.secondary">
                                Name: {dataRow.name}
                            </Typography>
                            <Typography gutterBottom variant="h6" color="text.secondary">
                                Station: {dataRow.station}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                Status: {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/} {dataRow.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : dataRow.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : dataRow.status == 'Active' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/>  : ''}  {dataRow.status}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={e=>handleShow(dataRow)} >
                                Show more
                            </Button>
                            
                        </CardActions>
                    </Card> 
                    ) 
            
            }  
        })
        setStDisplay(displaiC) 
    },[pending, onboarding, onboarded, inactive, offboarded])

    useEffect(() =>{
        setPending(false)
        setOnboarding(false)
        setInactive(false)
        setOnboarded(false)
        setOffboarded(false)
    }, [searchVal?.length > 0])

    const handleShow = (data) =>{
        setOpen(true)
        setFiles([])
        UserService.getProfileData({name: data.name}).then(
            response =>{
                setDetails(response.data)
                console.log(response.data)
            }
        )
        let name = {name : data.name ? data.name : ''}
        setName(name)
        UserService.getFiles(name).then(
            response => {
                let data = response.data
                data?.map(element =>{
                    setFiles(oldFiles => [...oldFiles, 
                        <button className="icon"  onClick={(e) => handleOpenPfd(e, element)}>
                            <img src={pdfImg} className="iconImage"/>
                            <p>{element}</p>
                        </button>
                    ])
                })
            }
        ).catch(err => console.log(err))
        console.log(data)
    }
    const handleShowClose = () =>{
        setValue('1')
        setOpen(false)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleShowClosePdf = () =>{
        setOpenPdf(false)
    }
    const handleOpenPfd = (e, element) =>{
        setOpenPdf(true)
        setPdfName(element)
    }
    const handleOpenAmzl = (e, amzl) =>{
        console.log(amzl)
        window.open(amzl)
    }
    const handleImport = (e, name) =>{
        console.log(e.target.files[0])
        console.log(name)
        const formData = new FormData()
        formData.append('folder', name)
        formData.append('fileName', e.target.files[0]?.name)
        formData.append('file', e.target.files[0])

        UserService.importPdf(formData).then(
            response =>{
                
                console.log(response)
                UserService.getFiles({name: data.name}).then(
                    response1 => {
                        let data = response1.data
                        data?.map(element =>{
                            setFiles(oldFiles => [...oldFiles, 
                                <button className="icon"  onClick={(e) => handleOpenPfd(e, element)}>
                                    <img src={pdfImg} className="iconImage"/>
                                    <p>{element}</p>
                                </button>
                            ])
                        })
                    }
                ).catch(err => console.log(err))
            }
        ).catch(err => console.log(err))
    }
    return(
        <div className="profileArch">
            <input type="text" placeholder="search.." onChange={e => setSearchVal(e.target.value)}></input>
            <button onClick={handleSearch} className="buttonProfileSearch">
                <SearchOutlinedIcon style={{fontSize: '30px'}}/>
            </button>
            {
            pending == false ?
            <button className="buttonProfileSearch" onClick={handlePending}>
                <BlurOnOutlinedIcon style={{color : '#b3b300'}}/>Pending
            </button> : 
            <button className="buttonProfileSearch" onClick={handlePending} style={{backgroundColor: '#d6d6c2'}}>
                <BlurOnOutlinedIcon style={{color : '#b3b300'}}/>Pending
            </button>
            }
            {
            onboarding == false ?     
            <button className="buttonProfileSearch" onClick={handleOnboarding}>
                <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/>Onboarding
            </button> : 
            <button className="buttonProfileSearch" onClick={handleOnboarding} style={{backgroundColor: '#d6d6c2'}}>
                <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/>Onboarding
            </button>
            }
            {onboarded == false ?
            <button className="buttonProfileSearch" onClick={handleOnboarded}>
                <AdjustOutlinedIcon style={{color: '#3385ff'}}/>Active
            </button> : 
            <button className="buttonProfileSearch" onClick={handleOnboarded} style={{backgroundColor: '#d6d6c2'}}>
                <AdjustOutlinedIcon style={{color: '#3385ff'}}/>Active
            </button>
            }
            
            
            <div className="flexProfile">
                {stDisplay}
            </div>
            
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={open}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent>
                       { details ? 
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Info" value="1" />
                                    <Tab label="Bank Details" value="2" />
                                    <Tab label="Scorecard" value="3" />
                                    <Tab label="Offboarding" value="4" />
                                    <Tab label="Documents" value="5" />
                                </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <div>
                                        <div className="flexProfiles">
                                            <Picture link={`https://node.skso.co.uk:9040/documents/${details.name}/badge.jpg`}/>
                                            {/*<div className="infoTitle">
                                                <h3>Name: {details.name}</h3>
                                                <h3>Station: {details.station}</h3>
                                                <h3>Status: {details.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : details.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : details.status == 'Onboarded' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : details.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}{details.status}</h3>
                                            </div>*/}
                                            <table className="infoTitle">
                                                <tr >
                                                    <td className="rowsProfile">Name</td>
                                                    <td className="rowsProfile paddingProfileCell">{details.name}</td>
                                                </tr>
                                                <tr >
                                                    <td className="rowsProfile">Station</td>
                                                    <td className="rowsProfile paddingProfileCell">{details.station}</td>
                                                </tr>
                                                <tr >
                                                    <td className="rowsProfile">Status</td>
                                                    <td className="rowsProfile paddingProfileCell">{details.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : details.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : details.status == 'Active' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : details.status == 'Inactive' ? <TollIcon style={{color: '#eb7a3e'}}/> : details.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}{details.status}</td>
                                                </tr>
                                                <tr >
                                                    <td className="rowsProfile">Amzl Profile</td>
                                                    <td className="rowsProfile paddingProfileCell">
                                                        <button class="buttonSkOn" onClick={e => handleOpenAmzl(e, details.amzl_profile_link)} style={{marginTop: '10px'}}>
                                                            <span class="button-text">open profile</span>
                                                            <div class="fill-container"></div>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <h1 className="h1Profile">Basic information</h1>
                                        <div className="flexProfilesX">
                                            
                                            <table className="tableI infoTitle">
                                                <tr>
                                                    <td className="rowsProfileX">Account No</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.account_no ? details.account_no : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">SKSO Role</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.skso_role ? details.skso_role : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Personal Email</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.email ? details.email : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Phone No</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.phone ? details.phone : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Address 1</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.address1 ? details.address1 : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Address 1</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.address2 ? details.address2 : 'no data'}</td>
                                                </tr>
                                                
                                            </table>
                                            <table className="tableI infoTitle">
                                                <tr>
                                                    <td className="rowsProfileX">Town</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.town ? details.town : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">County</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.county ? details.county : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Post Code</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.post_code ? details.post_code : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Date Of Birth</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.dob ? details.dob : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Age</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.dob ? Math.floor((new Date().getTime() - new Date(details.dob).getTime())/31556926000) : 'no data'}</td>
                                                </tr>
                                                
                                            </table>
                                        </div>
                                        <h1 className="h1Profile">Additional information</h1>
                                        <div className="flexProfilesX">
                                            
                                            <table className="tableI infoTitle">
                                                <tr>
                                                    <td className="rowsProfileX">Vehicle Source</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.vehicle_source ? details.vehicle_source : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">DL Expiry Date</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.dl_expiry ? details.dl_expiry : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">DL Points</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.dl_points ? details.dl_points : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Onboarding Date</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.onboarding_date ? details.onboarding_date : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">UTR</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.active_utr ? details.active_utr : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">RTW1 Expiry Date</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.audit_rtw1_expiry ? details.audit_rtw1_expiry : 'no data'}</td>
                                                </tr>
                                            </table>
                                            <table className="tableI infoTitle">
                                                <tr>
                                                    <td className="rowsProfileX">Amzl Id</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.active_amzl_id ? details.active_amzl_id : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Company Name</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.active_co_name ? details.active_co_name : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Company Reg</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.active_co_reg ? details.active_co_reg : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Company Vat</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.active_co_vat ? details.active_co_vat : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">DL Check Date</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.audit_dl_check_date ? details.audit_dl_check_date : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">RTW2 Expiry Date</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.audit_rtw2_expiry ? details.audit_rtw2_expiry : 'no data'}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <h1 className="h1Profile">Notes</h1>
                                        <div className="notesProfile">
                                            {details.notes}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div className="flexProfiles">
                                        <AccountBalanceIcon style={{marginLeft: '30px',paddingLeft: '20px' ,fontSize: '200px', color: '#e2e2e2'}} />
                                        {/*<div className="infoTitle">
                                            <h3>Name: {details.name}</h3>
                                            <h3>Station: {details.station}</h3>
                                            <h3>Status: {details.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : details.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : details.status == 'Onboarded' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : details.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}{details.status}</h3>
                                        </div>*/}
                                        <table className="infoTitleBank">
                                            <tr >
                                                <td className="rowsProfile">Bank Name</td>
                                                <td className="rowsProfile paddingProfileCell">{details.active_bank ? 'Submitted' : 'no data'}</td>
                                            </tr>
                                            <tr >
                                                <td className="rowsProfile">Bank Holder</td>
                                                <td className="rowsProfile paddingProfileCell">{details.active_bank_name ? 'Submitted' : 'no data'}</td>
                                            </tr>
                                            <tr >
                                                <td className="rowsProfile">Bank Ref</td>
                                                <td className="rowsProfile paddingProfileCell">{details.active_bank_ref ? 'Submitted' : 'no data'}</td>
                                            </tr>
                                            <tr >
                                                <td className="rowsProfile">Bank Sort Code</td>
                                                <td className="rowsProfile paddingProfileCell">{details.active_bank_sort_code ? 'Submitted' : 'no data'}</td>
                                            </tr>
                                            <tr >
                                                <td className="rowsProfile">Bank Account No</td>
                                                <td className="rowsProfile paddingProfileCell">{details.active_bank_account_no ? 'Submitted' : 'no data'}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    
                                </TabPanel>
                                <TabPanel value="3">
                                <div className="flexProfiles">
                                        <DonutSmallOutlinedIcon style={{marginLeft: '30px',paddingLeft: '20px' ,fontSize: '200px', color: '#e2e2e2'}} />
                                        {/*<div className="infoTitle">
                                            <h3>Name: {details.name}</h3>
                                            <h3>Station: {details.station}</h3>
                                            <h3>Status: {details.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : details.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : details.status == 'Onboarded' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : details.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}{details.status}</h3>
                                        </div>*/}
                                        <table className="infoTitleBank">
                                            <tr >
                                                <td className="rowsProfile">Week</td>
                                                <td className="rowsProfile paddingProfileCell">{details.sc_score ? details.sc_score : 'no data'}</td>
                                            </tr>
                                            <tr >
                                                <td className="rowsProfile">Result</td>
                                                <td className="rowsProfile paddingProfileCell">{details.sc_result ? details.sc_result : 'no data'}</td>
                                            </tr>
                                            <tr >
                                                <td className="rowsProfile">Focus</td>
                                                <td className="rowsProfile paddingProfileCell">{details.sc_weekno ? details.sc_weekno : 'no data'}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <h1 className="h1Profile">Metrics</h1>
                                        <div className="flexProfilesX">
                                            
                                            <table className="tableI infoTitle">
                                                <tr>
                                                    <td className="rowsProfileX">Parcels Delivered</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_delivered ? details.sc_delivered : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Delivery Completition Rate (DCR)</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_dcr ? details.sc_dcr : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Delivered Not Received (DPMO) </td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_dpmo ? details.sc_dpmo : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Picture on Delivery (POD)</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_pod ? details.sc_pod : 'no data'}</td>
                                                </tr>
                                            </table>
                                            <table className="tableI infoTitle">
                                                <tr>
                                                    <td className="rowsProfileX">Contact Compliance (CC)</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_cc ? details.sc_cc : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Customer Escalation (CE)</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_ce ? details.sc_ce : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Customer Delivery Feedback (DEX)</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_dex ? details.sc_dex : 'no data'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="rowsProfileX">Score</td>
                                                    <td className="rowsProfileX paddingProfileCell">{details.sc_score ? details.sc_score : 'no data'}</td>
                                                </tr>
                                            </table>
                                        </div>
                                </TabPanel>
                                <TabPanel value="4">
                                <div className="flexProfiles">
                                    <NoAccountsOutlinedIcon style={{marginLeft: '30px',paddingLeft: '20px' ,fontSize: '200px', color: '#e2e2e2'}} />
                                    {/*<div className="infoTitle">
                                        <h3>Name: {details.name}</h3>
                                        <h3>Station: {details.station}</h3>
                                        <h3>Status: {details.status == 'Pending' ? <BlurOnOutlinedIcon style={{color : '#b3b300'}}/> : details.status == 'Onboarding' ? <BlurCircularOutlinedIcon style={{color: '#33cc33'}}/> : details.status == 'Onboarded' ? <AdjustOutlinedIcon style={{color: '#3385ff'}}/> : details.status == 'Offboarded' ? <FiberManualRecordOutlinedIcon style={{color : '#ff704d'}}/> : ''}{details.status}</h3>
                                    </div>*/}
                                    <table className="infoTitleBank">
                                        <tr >
                                            <td className="rowsProfile">Date</td>
                                            <td className="rowsProfile paddingProfileCell">{details.offboarding_date ? details.offboarding_date : 'no data'}</td>
                                        </tr>
                                        <tr >
                                            <td className="rowsProfile">Reason</td>
                                            <td className="rowsProfile paddingProfileCell">{details.offboarding_reason ? details.offboarding_reason : 'no data'}</td>
                                        </tr>
                                        <tr >
                                            <td className="rowsProfile">Badge Status</td>
                                            <td className="rowsProfile paddingProfileCell">{details.offboarding_badge_status ? details.offboarding_badge_status : 'no data'}</td>
                                        </tr>
                                        <tr >
                                            <td className="rowsProfile">Security Aware</td>
                                            <td className="rowsProfile paddingProfileCell">{details.offboarding_security_aware ? details.offboarding_security_aware : 'no data'}</td>
                                        </tr>
                                        <tr >
                                            <td className="rowsProfile">Action By</td>
                                            <td className="rowsProfile paddingProfileCell">{details.offboarding_action_by ? details.offboarding_action_by : 'no data'}</td>
                                        </tr>
                                    </table>
                                </div>
                                </TabPanel>
                                <TabPanel value="5">
                                    <div>
                                        <input
                                            accept="file/*"
                                            //className={classes.input}
                                            style={{display: 'none'}}
                                            id="icon-button-file"
                                            type="file"
                                            //capture="environment"
                                            onChange={(e) => handleImport(e, details.name)}
                                        />
                                        <label htmlFor="icon-button-file">
                                            <img src={importPdf}/>
                                            <label className="label2 " style={{marginLeft: '15px'}}>Import</label>
                                        </label>
                                    </div>
                                    <div className="flex contIcons">
                                        {files}
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box> : ''
                        }
                    </DialogContent>
                    <DialogActions>
                    
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleShowClose}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                        <div class="fill-container"></div>
                    </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openPdf}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{pdfName}</DialogTitle>
                    <DialogContent>
                        <iframe src={`https://node.skso.co.uk:9040/documents/${name?.name}/${pdfName}`} style={{width: '100%', height: '100%'}}/>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleShowClosePdf}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default Profiles;