import React from 'react';
import { useState, useEffect } from 'react';
import UserService from '../../services/user.service';
import { DataGrid, gridClasses,useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import { useAsyncError } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { alpha, styled } from '@mui/material/styles';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));


const AuditData = () =>{
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ displayData, setDisplayData ] = useState()
    const [ search, setSearch ] = useState([])
    const [ open, setOpen ] = useState(false)
    const [ value, setValue ] = useState('1')
    const [ name, setName ] = useState()
    const [ dlNo, setDlNo ] = useState()
    const [ dlEx, setDlEx ] = useState()
    const [ dlOrigin, setDlOrigin ] = useState()
    const [ dlPoints, setDlPoints ] = useState()
    const [ dlEndorsements, setDlEndorsements ] = useState()
    const [ dlCheck, setDlCheck ] = useState()
    const [ rtw1, setRtw1 ] = useState()
    const [ rtw1Doc, setRtw1Doc ] = useState()
    const [ rtw1Expiry, setRtw1Expiry ] = useState()
    const [ rtw2, setRtw2 ] = useState()
    const [ rtw2Doc, setRtw2Doc ] = useState()
    const [ rtw2Expiry, setRtw2Expiry ] = useState()
    const [ rtwCheck, setRtwCheck ] = useState()
    const [ rtw2CCheck, setRtw2CCheck ] = useState(false)
    const [ rtw1CCheck, setRtw1CCheck ] = useState(false)
    const [ rtwCCheck, setRtwCCheck ] = useState(false)
    const [ dlExCCheck, setDlExCCheck ] = useState(false)
    const [ dlCCheck ,setDlCCheck ]  = useState(false)
    const [ entry, setEntry ] = useState()
    const [ dlSuccess, setDlSuccess ] = useState(false)
    const [ dlFail, setDlFail ] = useState(false)
    const [ rtwSuccess, setRtwSuccess ] = useState(false)
    const [ rtwFail, setRtwFail ] = useState(false)

    let display = []


    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
        {
          field: 'name',
          headerName: 'Name',
          headerClassName: 'table-header',
          width: 288,
          editable: false,
        },
          {
            field: 'audit_dl_no',
            headerName: 'Dl No',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_dl_expiry',
            headerName: 'Dl Exp',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_dl_origin',
            headerName: 'Dl Origin',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_dl_points',
            headerName: 'Dl Points',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_dl_endorsements',
            headerName: 'Dl Endorsments',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_dl_check_date',
            headerName: 'Dl Check Date',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw1',
            headerName: 'RTW1',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw1_doc',
            headerName: 'RTW1 Doc',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw1_expiry',
            headerName: 'RTW1 Expiry',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw2',
            headerName: 'RTW2',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw2_doc',
            headerName: 'RTW2 Doc',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw2_expiry',
            headerName: 'RTW2 Expiry',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'audit_rtw_check',
            headerName: 'RTW Check',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
            {
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleParams(param.row)} /*onMouseEnter={handlePopoverOpenE} onMouseLeave={handlePopoverCloseE}*//>
                    </>
            )
          }
        ]

    
    useEffect(() =>{
        UserService.getAuditData().then(
            response =>{
                console.log(response.data)
                setData(response.data)
            }
        )
    },[])

    const handleClick = (params) =>{
        if(params.field == 'account_no'){
            console.log(params.row)
        }
    }
    data?.map(row =>{
        display.push({
            id: row.entry,
            status: row.status,
            name: row.name,
            audit_dl_no: row.audit_dl_no,
            audit_dl_expiry: row.audit_dl_expiry ? row.audit_dl_expiry?.split('-')[2]+'-'+row.audit_dl_expiry?.split('-')[1]+'-'+row.audit_dl_expiry?.split('-')[0] : '',
            audit_dl_origin: row.audit_dl_origin,
            audit_dl_points: row.audit_dl_points,
            audit_dl_endorsements: row.audit_dl_endorsements,
            audit_dl_check_date: row.audit_dl_check_date ? row.audit_dl_check_date?.split('-')[2]+'-'+row.audit_dl_check_date?.split('-')[1]+'-'+row.audit_dl_check_date?.split('-')[0] : '',
            audit_rtw1: row.audit_rtw1,
            audit_rtw1_doc: row.audit_rtw1_doc,
            audit_rtw1_expiry: row.audit_rtw1_expiry ? row.audit_rtw1_expiry?.split('-')[2]+'-'+row.audit_rtw1_expiry?.split('-')[1]+'-'+row.audit_rtw1_expiry?.split('-')[0] : '',
            audit_rtw2: row.audit_rtw2,
            audit_rtw2_doc: row.audit_rtw2_doc,
            audit_rtw2_expiry: row.audit_rtw2_expiry?.split('-').length > 1 ? row.audit_rtw2_expiry?.split('-')[2]+'-'+row.audit_rtw2_expiry?.split('-')[1]+'-'+row.audit_rtw2_expiry?.split('-')[0] : '',
            audit_rtw_check: row.audit_rtw_check ? row.audit_rtw_check?.split('-')[2]+'-'+row.audit_rtw_check?.split('-')[1]+'-'+row.audit_rtw_check?.split('-')[0] : ''
        })
    })

    const handleParams = (params) =>{
        setEntry(params.id)
        setName(params.name)
        setDlNo(params.audit_dl_no)
        setDlEx(params.audit_dl_expiry?.split('-').length > 1 ? params.audit_dl_expiry?.split('-')[2]+'-'+params.audit_dl_expiry?.split('-')[1]+'-'+params.audit_dl_expiry?.split('-')[0] : '')
        console.log(params.audit_dl_expiry?.split('-')[2]+'-'+params.audit_dl_expiry?.split('-')[1]+'-'+params.audit_dl_expiry?.split('-')[0])
        setDlOrigin(params.audit_dl_origin)
        setDlPoints(params.audit_dl_points)
        setDlEndorsements(params.audit_dl_endorsements)
        setDlCheck(params.audit_dl_check_date?.split('-').length > 1 ? params.audit_dl_check_date?.split('-')[2]+'-'+params.audit_dl_check_date?.split('-')[1]+'-'+params.audit_dl_check_date?.split('-')[0] : '')
        setRtw1(params.audit_rtw1)
        setRtw1Doc(params.audit_rtw1_doc)
        setRtw1Expiry(params.audit_rtw1_expiry?.split('-').length > 1 ? params.audit_rtw1_expiry?.split('-')[2]+'-'+params.audit_rtw1_expiry?.split('-')[1]+'-'+params.audit_rtw1_expiry?.split('-')[0] : '')
        setRtw2(params.audit_rtw2)
        setRtw2Doc(params.audit_rtw2_doc)
        setRtw2Expiry(params.audit_rtw2_expiry?.split('-').length > 1 ? params.audit_rtw2_expiry?.split('-')[2]+'-'+params.audit_rtw2_expiry?.split('-')[1]+'-'+params.audit_rtw2_expiry?.split('-')[0] : '')
        setRtwCheck(params.audit_rtw_check?.split('-').length > 1 ? params.audit_rtw_check?.split('-')[2]+'-'+params.audit_rtw_check?.split('-')[1]+'-'+params.audit_rtw_check?.split('-')[0] : '')
        setOpen(true)
        console.log(params)
    }
    const handleSearch = (e) =>{
        let displayS = []
        
        data?.map(row =>{
            if(row.name?.toLowerCase().match(e.target.value?.toLowerCase())){
                console.log(e.target.value)
                displayS.push({
                    id: row.entry,
                    status: row.status,
                    name: row.name,
                    audit_dl_no: row.audit_dl_no,
                    audit_dl_expiry: row.audit_dl_expiry ? row.audit_dl_expiry?.split('-')[2]+'-'+row.audit_dl_expiry?.split('-')[1]+'-'+row.audit_dl_expiry?.split('-')[0] : '',
                    audit_dl_origin: row.audit_dl_origin,
                    audit_dl_points: row.audit_dl_points,
                    audit_dl_endorsements: row.audit_dl_endorsements,
                    audit_dl_check_date: row.audit_dl_check_date ? row.audit_dl_check_date?.split('-')[2]+'-'+row.audit_dl_check_date?.split('-')[1]+'-'+row.audit_dl_check_date?.split('-')[0] : '',
                    audit_rtw1: row.audit_rtw1,
                    audit_rtw1_doc: row.audit_rtw1_doc,
                    audit_rtw1_expiry: row.audit_rtw1_expiry ? row.audit_rtw1_expiry?.split('-')[2]+'-'+row.audit_rtw1_expiry?.split('-')[1]+'-'+row.audit_rtw1_expiry?.split('-')[0] : '',
                    audit_rtw2: row.audit_rtw2,
                    audit_rtw2_doc: row.audit_rtw2_doc,
                    audit_rtw2_expiry: row.audit_rtw2_expiry ? row.audit_rtw2_expiry?.split('-')[2]+'-'+row.audit_rtw2_expiry?.split('-')[1]+'-'+row.audit_rtw2_expiry?.split('-')[0] : '',
                    audit_rtw_check: row.audit_rtw_check ? row.audit_rtw_check?.split('-')[2]+'-'+row.audit_rtw_check?.split('-')[1]+'-'+row.audit_rtw_check?.split('-')[0] : ''
                })
            }
        })
        setSearch(displayS)
    }
    const handleShowClose = () =>{
        window.location.reload(false)
        setValue('1')
        setOpen(false)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const openRtW1C = () =>{
        setRtw1CCheck(true)
    }
    const closeRtw1C = () =>{
        setRtw1CCheck(false)
    }
    const openRtW2C = () =>{
        setRtw2CCheck(true)
    }
    const closeRtw2C = () =>{
        setRtw2CCheck(false)
    }
    const openRtwC = () =>{
        setRtwCCheck(true)
    }
    const closeRtwC = () =>{
        setRtwCCheck(false)
    }
    const openDlC = () =>{
        setDlExCCheck(true)
    }
    const closeDlC = () =>{
        setDlExCCheck(false)
    }
    const openDlCC = () =>{
        setDlCCheck(true)
    }
    const closeDlCC = () =>{
        setDlCCheck(false)
    }
    const dateChange = (e) =>{
        setRtwCheck(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }
    const dateChange1 = (e) =>{
        setRtw1Expiry(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }
    const dateChange2 = (e) =>{
        setRtw2Expiry(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }
    const dateChangeDL1 = (e) =>{
        setDlEx(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }
    const dateChangeDL2 = (e) =>{
        setDlCheck(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }
    const updateDLData = () =>{
        const dataDL = {entry: entry, audit_dl_no: dlNo, audit_dl_expiry: dlEx, audit_dl_origin: dlOrigin, audit_dl_points: dlPoints, audit_dl_endorsements: dlEndorsements, audit_dl_check_date: dlCheck}
        UserService.updateDl(dataDL).then(
            response =>{
                if(response.status == 200){
                    setDlSuccess(true)
                    sleep(3000).then(()=>{
                        setDlSuccess(false)
                    })
                }else{
                    setDlFail(true)
                }
            }
        )
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    const updateRtwData = () =>{
        const dataRtw = {
            entry: entry, 
            audit_rtw1: rtw1, 
            audit_rtw1_doc: rtw1Doc, 
            audit_rtw1_expiry: rtw1Expiry, 
            audit_rtw2: rtw2, 
            audit_rtw2_doc: rtw2Doc, 
            audit_rtw2_expiry: rtw2Expiry, 
            audit_rtw_check: rtwCheck
        }
        UserService.updateRtw(dataRtw).then(
            response =>{
                if(response.status == 200){
                    setRtwSuccess(true)
                    sleep(3000).then(()=>{
                        setRtwSuccess(false)
                    })
                }else{
                    setRtwFail(true)
                }
            }
        )
    }
    return(
        
        <div className='dashArch'>
            <div className="searchBar fixed ">
                    <input type="text" name='search' className="fullInput" placeholder="search user"/*defaultValue={email}*/ onChange={e => handleSearch(e)}  />
            </div>
            {search.length == 0 ?
            <Box sx={{ height: 1100, width: 3350, paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGrid
                
            // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={display}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    //pinnedColumns: { left: ['name'], right: ['actions'] } 
                    },
                }}
                sx={{fontSize: '16px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                onCellClick={handleClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                />
            </Box>:
            <Box sx={{ height: 1100, width: 3350, paddingLeft: '0%', marginTop:2 }}>
            <StripedDataGrid
                
            // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={search}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    //pinnedColumns: { left: ['name'], right: ['actions'] } 
                    },
                }}
                sx={{fontSize: '16px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                onCellClick={handleClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                />
            </Box> 
            }  
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={open}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >{name}</label></DialogTitle>
                    <DialogContent>
                       { data ? 
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Driving License" value="1" />
                                    <Tab label="Right to Work" value="2" />
                                  
                                </TabList>
                                </Box>
                                <TabPanel value="1">
                                    {dlSuccess == false ?
                                        <>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="dlNo" className="label2">DL Number</label>
                                                    <input type="text" name="dlNo" className="lone" value={dlNo} onChange={e => setDlNo(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="dlEx" className="label2">DL Expiry</label>
                                                    <input type="text" name="dlEx" className="inputEdit" value={dlEx ? dlEx?.split('-')[2]+'-'+dlEx?.split('-')[1]+'-'+dlEx?.split('-')[0]: ''} onClick={openDlC} onChange={e => setDlEx(e.target.value)}></input>
                                                </div>
                                                <div className="flexComp">
                                                    <label for="origin" className="label2 correction">DL Origin</label>
                                                    <input type="text" name="origin" className="inputEdit correction" value={dlOrigin}  onChange={e => setDlOrigin(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className='calendarL'>
                                                {dlExCCheck == true ?
                                                        <>
                                                            <Calendar  
                                                                onChange={e => dateChangeDL1(e)} 
                                                                style={{marginLeft: '200px'}}
                                                                value={dlEx && dlEx !== '0000-00-00' && dlEx !== 'Null' ? new Date(dlEx): new Date()} 
                                                                validRange = {{
                                                                    start: new Date(),
                                                                    end: null
                                                                }}
                                                                
                                                                className={'calendarX'}
                                                            />
                                                            <CloseRoundedIcon onClick={closeDlC}>Close</CloseRoundedIcon>
                                                        </> 
                                                        : ''
                                                }
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="dlPoints" className="label2">DL Points</label>
                                                    <input type="text" name="dlPoints" className="inputEdit" value={dlPoints} onChange={e => setDlPoints(e.target.value)}></input>
                                                </div>
                                                <div className="flexComp">
                                                    <label for="endorsements" className="label2 correction">DL Endorsements</label>
                                                    <input type="text" name="endorsements" className="inputEdit correction" value={dlEndorsements} onChange={e => setDlEndorsements(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="checkDate" className="label2">DL Check Date</label>
                                                    <input type="text" name="checkDate" className="lone" value={dlCheck ? dlCheck.split('-')[2]+'-'+dlCheck.split('-')[1]+'-'+dlCheck.split('-')[0] : ''} onClick={openDlCC} onChange={e => setDlCheck(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className='calendarL'>
                                                {dlCCheck == true ?
                                                        <>
                                                            <Calendar  
                                                                onChange={e => dateChangeDL2(e)} 
                                                                style={{marginLeft: '200px'}}
                                                                value={dlCheck && dlCheck !== '0000-00-00' ? new Date(dlCheck): new Date()} 
                                                                validRange = {{
                                                                    start: new Date(),
                                                                    end: null
                                                                }}
                                                                
                                                                className={'calendarX'}
                                                            />
                                                            <CloseRoundedIcon onClick={closeDlCC}>Close</CloseRoundedIcon>
                                                        </> 
                                                        : ''
                                                }
                                            </div>
                                            {/*<button className="editButton" onClick={updateDLData}>Update DL Check Data</button>*/} 
                                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={updateDLData}>
                                                <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update DL Check Data</span>
                                                <div class="fill-container"></div>
                                            </button>
                                        </> : dlFail == true ? <label for="select" className="label" >Something whent wrong!</label> : <label for="select" className="label" >Info Updated!</label>
                                    }
                                </TabPanel>
                                <TabPanel value="2">
                                    {rtwSuccess == false ?
                                        <>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="rtw1" className="label2">RTW1</label>
                                                    <input type="text" name="rtw1" className="lone" value={rtw1} onChange={e => setRtw1(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="doc1" className="label2">RTW1 Doc</label>
                                                    <input type="text" name="doc1" className="inputEdit" value={rtw1Doc} onChange={e => setRtw1Doc(e.target.value)}></input>
                                                </div>
                                                <div className="flexComp">
                                                    <label for="expiry1" className="label2 correction">RTW1 Expiry</label>
                                                    <input type="text" name="expiry1" className="inputEdit correction" value={rtw1Expiry ? rtw1Expiry.split('-')[2]+'-'+rtw1Expiry.split('-')[1]+'-'+rtw1Expiry.split('-')[0] : ''} onClick={openRtW1C} onChange={e => setRtw1Expiry(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className='calendar'>
                                                {rtw1CCheck == true ?
                                                    <>
                                                        <Calendar  
                                                            onChange={e => dateChange1(e)} 
                                                            style={{marginLeft: '200px'}}
                                                            value={rtw1Expiry ? new Date(rtw1Expiry): ''} 
                                                            validRange = {{
                                                                start: new Date(),
                                                                end: null
                                                            }}
                                                            onBlur={closeRtw1C}
                                                            className={'calendarX'}
                                                        />
                                                        <CloseRoundedIcon onClick={closeRtw1C}>Close</CloseRoundedIcon>
                                                    </> 
                                                    : ''
                                                }
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="rtw2" className="label2">RTW2</label>
                                                    <input type="text" name="rtw2" className="lone" value={rtw2} onChange={e => setRtw2(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="doc2" className="label2">RTW2 Doc</label>
                                                    <input type="text" name="doc2" className="inputEdit" value={rtw2Doc} onChange={e => setRtw2Doc(e.target.value)}></input>
                                                </div>
                                                <div className="flexComp">
                                                    <label for="expiry2" className="label2 correction">RTW2 Expiry</label>
                                                    <input type="text" name="expiry2" className="inputEdit correction" value={rtw2Expiry ? rtw2Expiry.split('-')[2]+'-'+rtw2Expiry.split('-')[1]+'-'+rtw2Expiry.split('-')[0] : ''} /*onBlur={closeRtw2C}*/ onClick={openRtW2C} onChange={e => setRtw2Expiry(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className='calendar'>
                                                {rtw2CCheck == true ?
                                                    <>
                                                        <Calendar  
                                                            onChange={e => dateChange2(e)} 
                                                            style={{marginLeft: '200px'}}
                                                            value={rtw2Expiry ? new Date(rtw2Expiry): new Date()} 
                                                            validRange = {{
                                                                start: new Date(),
                                                                end: null
                                                            }}
                                                            onBlur={closeRtw2C}
                                                            className={'calendarX'}
                                                        />
                                                        <CloseRoundedIcon onClick={closeRtw2C}>Close</CloseRoundedIcon>
                                                    </> 
                                                    : ''
                                                }
                                            </div>
                                            <div className="flex">
                                                <div className="flexComp">
                                                    <label for="checkRtw" className="label2">RTW Check</label>
                                                    <input type="text" name="checkRtw" className="lone" value={rtwCheck  ? rtwCheck.split('-')[2]+'-'+rtwCheck.split('-')[1]+'-'+rtwCheck.split('-')[0] : ''} onClick={openRtwC} onChange={e => setRtwCheck(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className='calendarL'>
                                                {rtwCCheck == true ?
                                                        <>
                                                            <Calendar  
                                                                onChange={e => dateChange(e)} 
                                                                style={{marginLeft: '200px'}}
                                                                value={rtwCheck && rtwCheck !== '0000-00-00' ? new Date(rtwCheck): new Date()} 
                                                                validRange = {{
                                                                    start: new Date(),
                                                                    end: null
                                                                }}
                                                                onBlur={closeRtwC}
                                                                className={'calendarX'}
                                                            />
                                                            <CloseRoundedIcon onClick={closeRtwC}>Close</CloseRoundedIcon>
                                                        </> 
                                                        : ''
                                                }
                                            </div>
                                            {/*<button className="editButton" onClick={updateRtwData}>Update RTW Check Data</button>*/}
                                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={updateRtwData}>
                                                <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update RTW Check Data</span>
                                                <div class="fill-container"></div>
                                            </button>
                                        </> : rtwFail == true ? <label for="select" className="label" >Something whent wrong!</label> : <label for="select" className="label" >Info Updated!!</label>
                                    }
                                </TabPanel>
                            </TabContext>
                        </Box> : ''
                        }
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleShowClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>  
        </div>
        
    )
}
export default AuditData;